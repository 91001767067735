import axios from "axios";
import authHeader from "./AuthHeader";
import qs from "qs";
const API_URL = "https://leadgen.republish.nl/api";

class LeadService {
  createLead(payload) {
    return axios.post(API_URL + "/sponsors/2261/leads", payload, {
      headers: authHeader(),
    });
  }
  createIsolateLead(payload) {
    return axios.post(API_URL + "/sponsors/2288/leads", payload, {
      headers: authHeader(),
    });
  }
  createLaadpalenLead(payload) {
    return axios.post(API_URL + "/sponsors/2319/leads", payload, {
      headers: authHeader(),
    });
  }

  createZonnepanelenLead(payload) {
    return axios.post(API_URL + "/sponsors/2322/leads", payload, {
      headers: authHeader(),
    });
  }
  createZonnepanelen(payload) {
    return axios.post(API_URL + "/sponsors/2348/leads", payload, {
      headers: authHeader(),
    });
  }
  createLeadAirconditioning(payload) {
    return axios.post(API_URL + "/sponsors/2352/leads", payload, {
      headers: authHeader(),
    });
  }
  createLeadDakwerken(payload) {
    return axios.post(API_URL + "/sponsors/2402/leads", qs.stringify(payload), {
      headers: authHeader(),
    });
  }
  createLeadThuisbattarij(payload) {
    return axios.post(API_URL + "/sponsors/2391/leads", payload, {
      headers: authHeader(),
    });
  }
}

export default new LeadService();
