<template>
  <div>
    <div v-if="page == 'first'">
      <HeroSection :postdata="postdata" @nextPage="nextPage" />
      <WorkSection @nextPage="nextPage" />
      <SolarPanel />
      <PostCodeForm :postdata="postdata" @nextPage="nextPage" />
    </div>
    <div v-else>
      <QuestionsSection :postdata="postdata" @previousPage="previousPage" />
    </div>
  </div>
</template>

<script>
import HeroSection from "../components/airconditioning/HeroSection.vue";
import SolarPanel from "../components/airconditioning/SolarPanel.vue";
import PostCodeForm from "../components/airconditioning/PosCodeForm.vue";
import WorkSection from "../components/airconditioning/WorkSection.vue";
import QuestionsSection from "../components/airconditioning/QuestionsSection.vue";

export default {
  name: "Home",
  components: {
    HeroSection,
    SolarPanel,
    PostCodeForm,
    WorkSection,
    QuestionsSection,
  },
  data() {
    return {
      page: "first",
      postdata: {
        house_number: "",
        zip: "",
        typePost: false,
      },
    };
  },
  methods: {
    nextPage(type) {
      console.log(type);
      if (type) {
        this.postdata.typePost = true;
      }
      this.page = "second";
    },
    previousPage() {
      this.page = "first";
    },
  },
  watch: {
    // page() {
    //   if (this.page == "second") {
    //     setTimeout(() => {
    //       this.page = "third";
    //     }, 2500);
    //   }
    // },
  },

  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "969816563708997"),
      fbq("track", "PageView")
    ),
      document.head.appendChild(useScript);
  },

  created() {
    document.title = "Nederlands Advies";
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=969816563708997&ev=PageView&noscript=1" /></noscript>';
  },
};
</script>

<style lang="scss" scoped>
.navBar {
  font-family: "Fira Sans", sans-serif;
  max-width: 1040px !important;
}
.headerSection {
  font-family: "Fira Sans", sans-serif;
  background-image: url(../assets/imgs/headerMain.png);
  background-size: cover;
  background-position-x: 70%;
  @media only screen and (max-width: 767.98px) {
    background-size: cover;
    background-position-x: left;
  }
}
.bannerSecond {
  font-family: "Fira Sans", sans-serif;
  background-color: #f2f2f2 !important;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1040px !important;
  }
}
</style>
