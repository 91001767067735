<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />

        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">Wat is jouw adres?</p>

        <b-form @submit="handleNextQuestion">
          <div class="row no-gutters first-question">
            <div class="col-lg-10 col-12">
              <div class="row no-gutters">
                <div class="col-7 pr-1">
                  <b-form-input
                    v-model="details.zip"
                    placeholder="Postcode"
                    required
                    :state="details.zip ? zipValidation : null"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!zipValidation">
                    Voer een geldige postcode in
                  </b-form-invalid-feedback>
                </div>
                <div class="col-5 pl-1">
                  <b-form-input
                    v-model="details.house_number"
                    placeholder="Huisnr."
                    required
                    :state="details.house_number ? houseNumberValidation : null"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!houseNumberValidation">
                    Voer een geldige huisnr in
                  </b-form-invalid-feedback>
                </div>
                <div class="mt-3 auto-complete"></div>
              </div>

              <!-- Dynamisch weergegeven straat en stad -->
              <p v-if="address.street"> {{ address.street }} {{ details.house_number }}, {{ address.city }}</p>

              <div class="my-3" v-if="setAddress">
                <b-alert show dismissible class="alert-danger">
                  Combinatie komt niet overeen!
                </b-alert>
              </div>

              <b-button class="mt-3 w-100" type="submit">Volgende</b-button>
            </div>
          </div>
        </b-form>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col"> </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex"; 

export default {
  name: "First_Question",
  data() {
    return {
      zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
      houseRegex: /^[0-9A-Za-z\s\-]+$/,
      selected: null,
      setAddress: false,
      validHouseNumbers: [],  // Array voor geldige huisnummers
    };
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      street: state => state.street,
      city: state => state.city,
    }),
    address() {
      return {
        street: this.street,
        city: this.city,
      };
    },
    zipValidation() {
      return this.zipRegex.test(this.details.zip) || this.details.zip === "";
    },
    houseNumberValidation() {
      return this.houseRegex.test(this.details.house_number) || this.details.house_number === "";
    },
    isValidForm() {
      return this.zipValidation && this.houseNumberValidation && this.validHouseNumbers.includes(this.details.house_number);
    },
  },
  watch: {
    'details.zip'(newValue) {
      if (newValue && this.houseNumberValidation) {
        // Valideer postcode en haal geldige huisnummers op
        this.fetchValidHouseNumbers();
        this.fetchAddressDetails();
      }
    },
    'details.house_number'(newValue) {
      if (newValue && this.zipValidation) {
        // Valideer huisnummer op basis van de opgehaalde geldige huisnummers
        this.fetchAddressDetails();
      }
    },
  },
  methods: {
    fetchAddressDetails() {
      const houseNumberWithoutPunctuation = this.details.house_number.replace(/[\s-]/g, "");
      this.getStreets({
        zip: this.details.zip,
        house_number: houseNumberWithoutPunctuation,
        onSuccess: () => {
          // Zorg ervoor dat de Vuex-store de juiste waarden bijwerkt voor Google Maps
          this.$store.commit('SET_ADDRESS', { street: this.address.street, city: this.address.city });
          this.$store.commit('SetHouseNumber', this.details.house_number);
        },
        onError: () => {
          this.setAddress = true;
          this.setAddress = false;
        },
      });
    },
    async fetchValidHouseNumbers() {
      const authKey = 'P6JTU52clKYjZca8';
      const baseUrl = 'https://api.pro6pp.nl/v2/suggest/nl/streetNumber';
      const maxResults = 900;
      const url = `${baseUrl}?postalCode=${encodeURIComponent(this.details.zip)}&authKey=${authKey}&maxResults=${maxResults}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Fout bij het ophalen van huisnummers.');
        }

        const data = await response.json();
        console.log("API respons:", data);
        
        if (data.length === 0) {
          console.log("Geen huisnummers gevonden voor deze postcode.");
          return;
        }

        const houseNumberCounts = {};
        data.forEach(item => {
          const houseNumber = item.streetNumber.toString().trim();
          if (!houseNumberCounts[houseNumber]) {
            houseNumberCounts[houseNumber] = 0;
          }
          houseNumberCounts[houseNumber]++;
        });

        this.validHouseNumbers = []; // Reset de array voor elke nieuwe oproep
        Object.keys(houseNumberCounts).forEach(number => {
          const count = houseNumberCounts[number];
          this.validHouseNumbers.push(number); // Voeg huisnummer toe

          // Voeg suffixes toe voor duplicaten
          if (count > 1) {
            for (let i = 0; i < count; i++) {
              const suffix = String.fromCharCode(97 + i); // 'a', 'b', 'c', etc.
              this.validHouseNumbers.push(`${number}${suffix}`);
            }
          }
        });

        console.log("Geldige huisnummers:", this.validHouseNumbers);
      } catch (error) {
        console.error('Er is een fout opgetreden bij het ophalen van huisnummers:', error);
        this.setAddress = true;
        // setTimeout(() => {
          this.setAddress = false;
        // }, 2000);
      }
    },
    handleNextQuestion(e) {
      e.preventDefault();

      if (this.isValidForm) {
        this.fetchAddressDetails();
        this.$emit("nextQuestion", 2);
      } else {
        this.setAddress = true; 
        setTimeout(() => {
          this.setAddress = false;
        }, 2000);
      }
    },
    goToPreviousPage() {
      this.$emit("previousPage");
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/firstQuest.png);
    background-size: 100% 100% !important;
  }
}

.street-dropdown {
  color: #bbbabc !important;
}

.auto-complete {
  width: 100% !important;
}

.invalid-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
