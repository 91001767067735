<template>
  <div class="work">
    <b-container>
      <p class="work__sub-title mb-0">Nederlandsadvies.nl</p>
      <p class="work__title">Hoe werkt het?</p>

      <b-row>
        <b-col
          v-for="(item, index) in items"
          :key="index"
          cols="12"
          md="4"
          class="h-100"
        >
          <WorkCard :item="item" />
        </b-col>
      </b-row>

      <!-- <b-row class="justify-content-center">
        <b-col cols="12" md="4">
          <b-button class="mt-5 w-100" @click="$emit('nextPage')">
            Start de WONINGSCAN</b-button
          >
        </b-col>
      </b-row> -->
    </b-container>
  </div>
</template>

<script>
import editIcon from "../../assets/imgs/edit.png";
import checkIcon from "../../assets/imgs/check.png";
import fileIcon from "../../assets/imgs/file.png";
import WorkCard from "./WorkCard.vue";
export default {
  name: "WorkSection",
  components: { WorkCard },
  data() {
    return {
      items: [
        {
          step: "Stap 1",
          description:
            "Doe de woningscan en controleer of jij in aanmerking komt voor de goedkoopste woningisolatie offertes.",
          icon: editIcon,
        },
        {
          step: "Stap 2",
          description:
            "Onze vakmensen geven gratis en vrijblijvend persoonlijk advies.",
          icon: fileIcon,
        },
        {
          step: "Stap 3",
          description:
            "Kies het beste aanbod en begin met besparen op woningisolatie en én je energierekening",

          icon: checkIcon,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.work {
  background: #1d1729 !important;
  padding-top: 100px;
  padding-bottom: 100px;

  &__sub-title {
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }

  &__title {
    font-size: 30px;
    color: #ffffff;
    font-weight: 800;
    text-align: center;
    line-height: 35px;
  }
  .btn {
    border-radius: 37px;
    // background-color: #1d1729;
    background-color: #ffcc01;
    padding: 10px;
    // font-size: 17px;
    font-size: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d1729;
    font-weight: 900;
    border: none !important;
  }

  @media (max-width: 769.99px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
</style>
