<template>
  <div
    class="d-flex w-100 h-100 justify-content-center align-items-center map-section"
  >
    <iframe
      width="100%"
      height="100%"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyCIUep3Zbrrok-Wm4Nsj_nwgWKZXIVUjBo&q=${location.lat},${location.lng}&center=${location.lat},${location.lng}&zoom=18&maptype=satellite`"
    >
    </iframe>
    <!-- <GmapMap
      :options="{
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: true,
        disableDefaultUi: false,
      }"
      :center="{
        lat: addressDetails?.lat ? addressDetails.lat : 0,
        lng: addressDetails?.lng ? addressDetails?.lng : 0,
      }"
      :zoom="18"
      map-type-id="hybrid"
      style="width: 100%; height: 100%; border-radius: 100px"
    > -->
    <!-- <GmapMarker
        :position="{
          lat: addressDetails?.lat ? addressDetails.lat : 0,
          lng: addressDetails?.lng ? addressDetails?.lng : 0,
        }"
        :clickable="true"
        :draggable="true"
      /> -->
    <!-- <StreetViewPanorama
        :position="{
          lat: location.lat,
          lng: location.lng,
        }"
        :pov="{
          heading: 0,
          pitch: 0,
        }"
        :zoom="1"
      /> -->
    <!-- </GmapMap> -->
    <!-- <span>Walter gropiuserf 6, Amersfoort</span> -->
    <div
      class="d-flex justify-content-center align-items-center question-card__right-col-text position-absolute"
    >
      <!-- <span v-if="address.results && address.results.length > 0">{{
        address?.results[0]?.formatted_address
      }}</span> -->
      <span>
        <template v-if="addressDetails.street">
          {{ address.street }} {{ address.streetNumber }}{{ address.premise }},
          {{ address.settlement }}
        </template>
        <template v-else>
          {{ address.street }} {{ address.streetNumber }}{{ address.premise }},
          {{ address.settlement }}
        </template>
      </span>
    </div>
    <!-- <span>{{ address?.results[0]?.formatted_address }}</span> -->
  </div>
</template>

<script>
import { StreetViewPanorama } from "vue2-google-maps";
export default {
  name: "GoogleMapSection",
  data() {
    return {
      // center: { lat: 37.7749, lng: -122.4194 },
      // zoom: 12,
    };
  },
  components: {
    StreetViewPanorama,
  },
  methods: {
    isValidCoordinates(lat, lng) {
      return !isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng));
    },
  },
};
</script>

<style lang="scss" scoped>
.map-section {
  //   border: 5px solid red !important;
  border-radius: 0px 30px 30px 0px !important;
  overflow: hidden !important;
}
</style>
