<template>
  <div class="questions-section">
    <b-container>
      <b-row
        class="justify-content-center"
        v-if="
          !(question === '2C' || question === 'form' || question === 'analyze')
        "
      >
        <b-col cols="12" xl="9">
          <b-progress :max="max">
            <b-progress-bar
              :value="value"
              :label="`${Math.ceil((value / max) * 100)}%`"
            ></b-progress-bar>
          </b-progress>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-4">
        <b-col xl="9" cols="12" v-if="question == '1'">
          <FirstQuestion
            :question="question"
            :max="max"
            :details="details"
            @nextQuestion="nextQuestion"
            @previousPage="goToPrevious"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '2'">
          <SecondQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          /> </b-col
        ><b-col xl="9" cols="12" v-else-if="question == '2B'">
          <SecondBQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          /> </b-col
        ><b-col xl="9" cols="12" v-else-if="question == '2C'">
          <SecondCQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '3'">
          <ThirdQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '4'">
          <FourthQuestion
            :question="question"
            :max="max"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '5'">
          <FifthQuestion
            :question="question"
            :max="max"
            :ans="ans"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
            @handleNextSelected="handleNextSelected"
          />
        </b-col>
        <b-col xl="9" cols="12" v-else-if="question == '6'">
          <SixthQuestion
            :question="question"
            :max="max"
            :ans="ans"
            @nextQuestion="nextQuestion"
            @previousQuestion="previousQuestion"
          />
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-4" v-if="question == 'analyze'">
        <b-col xl="9" cols="12">
          <AnalyzingSection
            @nextQuestion="nextQuestion"
            :responses="responses"
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-4" v-if="question == 'form'">
        <b-col xl="9" cols="12">
          <FormSection
            :answers="answers"
            :details="details"
            @nextQuestion="nextQuestion"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FirstQuestion from "./Questions/First_Question.vue";
import SecondQuestion from "./Questions/Second_Question.vue";
import SecondBQuestion from "./Questions/SecondB_Question.vue";
import SecondCQuestion from "./Questions/SecondC_Question.vue";
import ThirdQuestion from "./Questions/Third_Question.vue";
import FourthQuestion from "./Questions/Fourth_Question.vue";
import FifthQuestion from "./Questions/Fifth_Question.vue";
import SixthQuestion from "./Questions/Sixth_Question.vue";
import AnalyzingSection from "./Analyzing_Section.vue";
import FormSection from "./Form_isolate.vue";

export default {
  name: "Questions_Section",
  components: {
    FirstQuestion,
    SecondQuestion,
    SecondBQuestion,
    SecondCQuestion,
    ThirdQuestion,
    FourthQuestion,
    FifthQuestion,
    AnalyzingSection,
    FormSection,
    SixthQuestion,
  },

  data() {
    return {
      ans: "",
      question: "1",
      value: 1,
      max: 6,
      responses: "",
      details: {
        language: "nl_NL",
        site_custom_name: "Nederlandsadvies_kozijnen",
        site_custom_url: "https://nederlandsadvies.nl/kunststofkozijnen",
        publisher_id: "",
        site_subid: "",
        house_number: "",
        zip: "",
        gender: "",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
      },
      answers: ["4184"],
    };
  },
  props: {
    postdata: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.details.publisher_id = this.$route.query.site;
    this.details.site_subid = this.$route.query.click;
  },
  methods: {
    nextQuestion(que, ans) {
      console.log("que, ans: ", que, ans);

      this.ans = ans;
      if (que == "2B" || que == "2C") {
        this.question = que;
      } else {
        this.question = que;
        this.value = que;
      }
      if (Array.isArray(ans)) {
        this.answers = ans;
      } else {
        if (ans) {
          let temp = [...this.answers];
          temp.push(ans);
          this.answers = temp;
        }
      }
    },
    handleNextSelected(arr) {
      this.answers = this.answers.concat(arr);
      this.responses = arr;
      if (this.answers.includes("dak isoleren")) {
        this.question = "6";

        this.answers = this.answers.filter((answ) => {
          if (answ != "dak isoleren") {
            return answ;
          }
        });
      } else {
        // this.answers.push("4303");
        this.question = "analyze";
      }
    },
    goToPrevious() {
      this.$emit("previousPage");
    },
    previousQuestion(que) {
      if (que == "2") {
        this.answers = ["4187"];
      }
      if (que == "2") {
        this.answers = ["4187"];
      } else if (que == "3") {
        this.answers = this.answers.filter((answ) => {
          if (answ != "4187" && answ != "4190" && answ != "4193") {
            return answ;
          }
        });
      } else if (que == "4") {
        this.answers = this.answers.filter((answ) => {
          if (
            answ != "4214" &&
            answ != "4217" &&
            answ != "4220" &&
            answ != "4223" &&
            answ != "4226"
          ) {
            return answ;
          }
        });
      } else if (que == "5") {
        this.answers = this.answers.filter((answ) => {
          if (answ != "4229" && answ != "4232" && answ != "4235") {
            return answ;
          }
        });
      } else if (que == "6") {
        this.answers = this.answers.filter((answ) => {
          if (answ != "4297" && answ != "4300") {
            return answ;
          }
        });
      }
      this.question = que;
      this.value = que;
    },
  },
  mounted() {
    if (this.postdata?.typePost) {
      this.question = "2";
      this.details.house_number = this.postdata.house_number;
      this.details.zip = this.postdata.zip;
    }
  },
  watch: {
    // "details.house_number"(newVal, oldVal) {
    //   this.getStreets({
    //     zip: this.details.zip,
    //     house_number: this.details.house_number,
    //   });
    // },
    answers: {
      handler(newAnswers, oldAnswers) {
        console.log("Answers changed:", newAnswers);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.questions-section {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #fff5cc;

  .progress {
    height: 20px;
    border-radius: 14px;
    background-color: #ffffff;
  }

  .progress-bar {
    height: 20px;
    border-radius: 14px;
    background-color: #5cba01;
  }
}
</style>
