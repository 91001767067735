<template>
  <div class="form-card analyze-card">
    <input type="checkbox" class="hidden-checkbox" v-model="bootCheck" />
    <p class="m-0 form-card__title pb-3"> <span class="text-green">Gefeliciteerd!</span></p>

    <p class="m-0 form-card__sub-title" style="color: black"> {{ street }} {{ houseNumber }}, {{ city }}: komt in aanmerking!</p>
    <!-- {{ details.zip }} -->
    <!-- <p class="m-0 form-card__sub-title">
      Ontvang gratis en vrijblijvend 3 zonnepanelen offertes + advies op maat
      van de beste lokale specialisten.<span class="text-green">
        Vergelijk en bespaar tot 40% extra!</span
      >
    </p> -->
    <img
      src="../../assets/imgs/arrowform.png"
      class="arrowform d-flex d-md-none"
      alt="My Image"
    />
    <!-- <p class="m-0 form-card__sub-title">
      vrijblijvend, persoonlijk advies en offertes voor
    </p>
    <p class="m-0 form-card__sub-title">
      kunststof kozijnen van drie partners uit jouw regio
    </p>
    <p class="m-0 form-card__description">
      Al meer dan 1,2 miljoen mensen ging je voor.
    </p> -->
    <b-row class="justify-content-center mt-4" style="margin-right: 10px">
      <div class="d-flex flex-column align-items-start punten-formulier">
        <div class="d-flex justify-content-center mt-2 check-item">
          <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
          <p class="m-0 analyze-card__check">
            Jij hebt recht op <span class="text-green">21% belastingkorting</span>
          </p>
        </div>
        <div class="d-flex justify-content-center mt-2 check-item">
          <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
          <p class="m-0 analyze-card__check">
            Jouw gemiddelde opbrengsten met zonnepanelen zijn: <span class="text-green">€ 1.700,00 per jaar</span>
          </p>
        </div>
        <div class="d-flex justify-content-center mt-2 check-item">
          <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
          <p class="m-0 analyze-card__check">
            Jij 
            <span class="text-green">bespaart tot 40%</span> met het vergelijken van offertes
          </p>
        </div>
        <div class="d-flex justify-content-center mt-2 check-item">
          <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
          <p class="m-0 analyze-card__check">
            Wij hebben <span class="text-green">beschikbare</span> installateurs
            gevonden
          </p>
        </div>
      </div>
    </b-row>






    <b-form @submit="handleSubmit" class="mx-4 mx-md-0 grijs-form">
      <p style="color: black; font-size: 1.8vw; font-family: Catamaran; font-weight: 700;" class="header-form">Naar wie mogen we deze sturen?</p>

      <b-form-group v-slot="{ ariaDescribedby }" class="mt-3 mb-0">

        <b-form-radio-group
          id="radio-group-1"
          v-model="details.gender"
          :options="genderOptions"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
        >
        </b-form-radio-group>
        <b-form-invalid-feedback>
          Voer een geldige e-mailadres in
        </b-form-invalid-feedback>
      </b-form-group>
      <p v-if="genderNotSelected" class="invalid-text mb-0">
        Selecteer er ten minste één
      </p>




      <b-row>
        <b-col class="mt-3" cols="12" md="6">
          <b-form-input
            type="text"
            v-model="details.firstname"
            @input="handleFieldInput('firstname')"
            placeholder="Voornaam"
            :state="details.firstname ? firstNameValidation : null"
            required
          ></b-form-input>
          <b-form-invalid-feedback v-if="!firstNameValidation">
            <!-- Voer een geldige voornaam in -->
            voornaam niet correct
          </b-form-invalid-feedback> </b-col
        ><b-col class="mt-3" cols="12" md="6">
          <b-form-input
            type="text"
            v-model="details.lastname"
            @input="handleFieldInput('lastname')"
            placeholder="Achternaam"
            :state="details.lastname ? lastNameValidation : null"
            required
          ></b-form-input>
          <b-form-invalid-feedback v-if="!lastNameValidation">
            <!-- Voer een geldige achternaam in -->
            achternaam niet correct
          </b-form-invalid-feedback> </b-col
        ><b-col class="mt-3" cols="12" md="6">
          <b-form-input
            placeholder="Telefoonnummer"
            type="text"
            @input="handleFieldInput('phone_number')"
            v-model="details.phone_number"
            :state="details.phone_number ? phoneValidation : null"
            required
          ></b-form-input>
          <b-form-invalid-feedback v-if="!phoneValidation">
            <!-- Voer een geldige telefoonnummer in -->
            telefoonnummer niet correct
          </b-form-invalid-feedback> </b-col
        ><b-col class="mt-3" cols="12" md="6">
          <b-form-input
            type="text"
            @input="handleFieldInput('email')"
            v-model="details.email"
            placeholder="E-mailadres"
            :state="details.email ? emailValidation : null"
            required
          ></b-form-input>
          <b-form-invalid-feedback v-if="!emailValidation">
            <!-- Voer een geldige e-mailadres in -->
            e-mailadres niet correct
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <!-- <p class="form-card__label">
        Ben je geïnteresseerd in het aanschaffen van zonnepanelen <br />
        Kies wat op jou van toepassing is:
      </p>
      <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
        <b-form-radio-group
          v-model="interested"
          :options="interestedOptions"
          :aria-describedby="ariaDescribedby"
          name="radios-stacked"
          stacked
        ></b-form-radio-group>
      </b-form-group> -->
      <!-- <p v-if="isInterestedSelected" class="invalid-text mb-0">
        Selecteer er ten minste één
      </p> -->

      <b-button class="w-100 p-2 mt-3" type="submit" :disabled="leadLoading">
        <b-spinner variant="dark" v-if="leadLoading"></b-spinner>
        <span v-else class="font-weight-bolder">AANVRAAG AFRONDEN</span>
      </b-button>
    </b-form>

    <p class="mb-0 mt-4 form-card__text">
      Je gegevens worden uitsluitend gebruikt om jou te voorzien van gratis,
      <br />
      persoonlijk advies en offertes van onze partners.
      <br />
      <b>
        Dit is is volledig vrijblijvend, je zit dus nergens aan vast.

      </b>
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Form_isolate",

  props: {
    details: {
      type: Object,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      bootCheck: false,
      nameRegex: /^[a-zA-Z\s.,'-]{1,30}$/,
      streetRegex: /^[a-zA-Z ]*$/,
      phoneRegex: /^(0|\+31|0031|00 31)([-\s]?\d[-\s]?){9}$/,
      emailRegex: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/,
      zipRegex: /^([0-9]{4}[a-zA-Z]{2})$/,
      houseRegex: "^[0-9]+$",
      genderNotSelected: false,
      

      genderOptions: [
        { text: "Mevrouw", value: "female" },
        { text: "Meneer", value: "male" },
      ],
    };
  },

  methods: {
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      console.log(this.details);
      const phoneNumberWithoutPunctuation = this.details.phone_number
        .replace(/[\s-]/g, "")
        .replace(/^00(\d+)$/, "$1");
      if (this.isValidForm && this.details.gender != "") {
        this.createZonnepanelen({
          data: {
            ...this.details,
            bootCheck: this.bootCheck,
            answers: [...this.answers],
            street: this.address.street,
            city: this.address.settlement,
            phone_number: phoneNumberWithoutPunctuation,
          },
        });
      } else {
        if (this.details.gender == "") {
          this.genderNotSelected = true;
          setTimeout(() => {
            this.genderNotSelected = false;
          }, 2500);
        }
      }
    },
  },


  

  computed: {
    ...mapGetters(["getErrorResponses","houseNumber" ]),

    street() {
    return this.$store.getters.street;
  },
  city() {
    return this.$store.getters.city;
  },
  houseNumber() {
      return this.$store.getters.houseNumber;
    },

  

    zipValidation() {
      return this.zipRegex.test(this.details.zip) || this.details.zip === "";
    },
    houseNumberValidation() {
      return (
        this.houseRegex.test(this.details.house_number) ||
        this.details.house_number === ""
      );
    },
    firstNameValidation() {
      if (this.details.firstname === "" && !this.getErrorResponses.firstname) {
        return null;
      }
      if (this.getErrorResponses.firstname == false) {
        return false;
      } else if (
        this.nameRegex.test(this.details.firstname) ||
        this.details.firstname === ""
      ) {
        return true;
      }
      return (
        this.nameRegex.test(this.details.firstname) ||
        this.details.firstname === ""
      );
    },
    lastNameValidation() {
      if (this.details.lastname === "" && !this.getErrorResponses.lastname) {
        return null;
      }
      if (this.getErrorResponses.lastname == false) {
        return false;
      } else if (
        this.nameRegex.test(this.details.lastname) ||
        this.details.lastname === ""
      ) {
        return true;
      }
      return (
        this.nameRegex.test(this.details.lastname) ||
        this.details.lastname === ""
      );
    },
    phoneValidation() {
      if (
        this.details.phone_number === "" &&
        !this.getErrorResponses.phone_number
      ) {
        return null;
      }
      if (this.getErrorResponses.phone_number == false) {
        return false;
      } else if (
        this.phoneRegex.test(this.details.phone_number) ||
        this.details.phone_number === ""
      ) {
        return true;
      }
      return (
        this.phoneRegex.test(this.details.phone_number) ||
        this.details.phone_number === ""
      );
    },
    emailValidation() {
      if (this.details.email === "" && !this.getErrorResponses.email) {
        return null;
      }
      if (this.getErrorResponses.email == false) {
        return false;
      } else if (
        this.emailRegex.test(this.details.email) ||
        this.details.email === ""
      ) {
        return true;
      }
      return (
        this.emailRegex.test(this.details.email) || this.details.email === ""
      );
    },

    isValidForm() {
      return (
        this.firstNameValidation &&
        this.lastNameValidation &&
        this.phoneValidation &&
        this.emailValidation
      );
    },
  },
};
</script>

<style lang="scss" scoped>

.grijs-form {
  background-color: #F5F5F5;
  padding: 30px;
  border-radius: 1vw;
  margin-top: 2vw;
}



.arrowform {
  height: 30px;
  rotate: 50deg;
}
.text-green {
  color: #5cba01 !important;
}
.hidden-checkbox {
  opacity: 0;
}
.form-card {
  border-radius: 30px;
  filter: drop-shadow(0 0 13.5px rgba(0, 0, 0, 0.21));
  background-color: #ffffff;
  //   padding: 50px;
  padding: 60px 80px;
  color: #1d1729;

  .invalid-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
    font-size: 13px;
  }

  &__title {
    text-align: center;
    font-size: 35px;
    // font-size: 44px;
    color: #1d1729;
    font-weight: bold;
  }
  &__sub-title {
    text-align: center;
    // font-size: 30px;
    font-size: 25px;
    color: #1d1729;
    font-weight: bold;
    line-height: 30px;
  }
  &__description {
    text-align: center;
    // font-size: 18px;
    font-size: 14px;
    color: #ffcc01;
    font-weight: 800;
  }

  &__label {
    // font-size: 22px;
    font-size: 18px;
    letter-spacing: 2px;
    // line-height: 33px;
    color: #000000;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .btn {
    border-radius: 37px;
    background-color: #ffcc01;
    font-size: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d1729;
    font-weight: bold;
    border: none !important;
    &:hover,
    :active {
      background-color: #ffcc01;
      color: #1d1729;
    }
  }

  &__text {
    //   font-size: 18px;
    font-size: 16px;
    color: #1d1729;
    text-align: center;
  }

  @media (max-width: 991.99px) {
    padding: 50px 60px;

    &__title {
      font-size: 32px;
    }
    &__sub-title {
      font-size: 22px;
    }
    &__description {
      font-size: 12px;
    }

    &__label {
      font-size: 15px;
    }

    .btn {
      font-size: 18px;
    }
    &__text {
      font-size: 14px;
    }
  }

  @media (max-width: 767.99px) {
    padding: 45px 50px;

    &__title {
      font-size: 28px;
    }
    &__sub-title {
      font-size: 18px;
      line-height: 25px;
    }
    &__description {
      font-size: 11px;
    }

    &__label {
      font-size: 11px;
    }

    .btn {
      font-size: 14px;
      letter-spacing: 1px;
    }
    &__text {
      font-size: 12px;
    }
  }

  @media (max-width: 575.99px) {
    padding: 30px 25px;

    &__title {
      font-size: 24px;
    }
    &__sub-title {
      font-size: 15px;
      line-height: 20px;
    }
    &__description {
      font-size: 10px;
    }

    &__label {
      font-size: 10px;
    }

    .btn {
      font-size: 12px;
      letter-spacing: 1px;
    }
    &__text {
      font-size: 11px;
    }
  }

  @media (max-width: 448.99px) {
    padding: 25px 15px;

    &__title {
      font-size: 16px;
    }
    &__sub-title {
      font-size: 14px;
      line-height: 15px;
    }
    &__description {
      font-size: 9px;
    }

    &__label {
      font-size: 9px;
    }

    .btn {
      font-size: 10px;
      letter-spacing: 1px;
    }
    &__text {
      font-size: 9px;
    }
  }
}



@media (max-width: 1199px) {
  .analyze-card {
    padding: 50px 152px!important;
  }
}

@media (max-width: 996px) {
  .analyze-card {
    padding: 50px 60px !important;
  }
}

@media (max-width: 765px) {
  .analyze-card {
    padding: 50px 0!important;
  }

  .punten-formulier {
    align-items: flex-start !important;
    margin-left: 6vw;
    padding-right: 6vw;
  }

  .arrowform {
    margin-left: 30px;
  }
}

@media (max-width: 550px) and (min-width: 499px) {
  
}

.analyze-card {
  border-radius: 30px;
  filter: drop-shadow(0 0 13.5px rgba(0, 0, 0, 0.21));
  background-color: #ffffff;
  padding: 50px 100px;
  color: #1d1729;

  // &__top-text {
  //   font-size: 35px;
  //   font-weight: 800;
  //   text-align: center;
  // }

  // &__progress-text {
  //   font-size: 18px;
  //   font-weight: 800;
  //   text-align: center;
  //   margin-top: 15px;
  //   margin-bottom: 40px;
  // }

  &__check {
    font-size: 18px;
    font-weight: 700;
  }








  // &__bottom-text {
  //   font-size: 24px;
  //   font-weight: 800;
  // }

  .check-item {
    // img {
    //   width: 18px !important;
    //   height: 18px !important;
    // }
    display: flex;
    align-items: center;
  }

  .bottom-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;

    img {
      width: 35px;
      height: 30px;
    }
  }

  @media (max-width: 991.99px) {
    padding: 40px;
    &__top-text {
      font-size: 30px;
    }

    &__progress-text {
      font-size: 16px;
      margin-bottom: 35px;
    }

    &__check {
      font-size: 16px;
    }

    &__bottom-text {
      font-size: 20px;
    }

    .bottom-item {
      margin-top: 30px;
      img {
        width: 30px;
        height: 25px;
      }
    }
  }

  @media (max-width: 767.99px) {
    padding: 30px;

    &__top-text {
      font-size: 25px;
    }

    &__progress-text {
      font-size: 14px;
      margin-bottom: 30px;
    }

    &__check {
      font-size: 14px;
    }

    &__bottom-text {
      font-size: 18px;
    }

    .bottom-item {
      margin-top: 25px;
      img {
        width: 25px;
        height: 20px;
      }
    }
  }

  @media (max-width: 575.99px) {
    padding: 30px 25px;
    .progress {
      height: 20px;
    }
    &__top-text {
      font-size: 22px;
    }

    &__progress-text {
      font-size: 12px;
      margin-bottom: 25px;
    }

    &__check {
      font-size: 12px;
    }

    &__bottom-text {
      font-size: 16px;
    }

    .bottom-item {
      margin-top: 20px;
      img {
        width: 23px;
        height: 18px;
      }
    }

    .check-item {
      img {
        width: 12px !important;
        height: 12px !important;
      }
      display: flex;
      align-items: flex-start;
    }
  }




  @media (max-width: 550px) {
    .grijs-form {
      background-color: #F5F5F5;
      border-radius: 1vw;
      margin-top: 2vw;
      width: 102%;
      margin-left: -1% !important;
    }

    .form-card .form-control {
      scale: 1.15;
    }

  }




  @media (max-width: 481.99px) {
    padding: 25px 20px;
    &__top-text {
      font-size: 18px;
    }

    &__progress-text {
      font-size: 10px;
      margin-bottom: 25px;
    }

    &__check {
      font-size: 10px;
    }

    &__bottom-text {
      font-size: 14px;
    }

    .bottom-item {
    }
  }
}
</style>
