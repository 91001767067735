<template>
  <div>
    <div class="col-lg-12 section-row-col-class-456135" style="">
      <div class="space-1 section-row-col-content-class-542326" style="">
        <ul>
          <li>
            Deze algemene voorwaarden zijn van toepassing op alle deelnemers aan
            bezochte campagne/actie. Deelname aan de campagne impliceert de
            volledige aanvaarding van deze voorwaarden.
          </li>

          <li>
            Deze campagne is geïnitieerd door <b>Solvari</b> (hierna te noemen:
            "Opdrachtgever") en wordt uitgevoerd in opdracht van Opdrachtgever
            door Re:publish BV (Re:publish is een conversie marketing netwerk,
            hierna te noemen Re:publish of Organisator).
          </li>
          <li>
            Deelname aan deze campagne is kosteloos, tenzij anders vermeld.
          </li>
          <li>
            De campagne is geldig t/m 31-12-{{ new Date().getFullYear() }}.
          </li>
          <li>
            Deelname aan de campagne staat open voor personen ouder dan 18 jaar.
          </li>
          <li>
            Door uw gegevens in te vullen en online te verzenden, geeft u blijk
            van interesse in de dienst, service en/of producten zoals beschreven
            in deze campagne, die worden aangeboden door de Opdrachtgever.
          </li>
          <li>
            Door deel te nemen, stemt de deelnemer in met het verzamelen en
            verwerken van hun persoonsgegevens. De gegevens die door een
            deelnemer worden verstrekt, worden opgeslagen in een gegevensbestand
            van Re:publish. Re:publish behandelt uw gegevens met uiterste
            zorgvuldigheid zoals beschreven in het privacybeleid.
          </li>

          <li>
            U stemt ermee in dat Re:publish uw gegevens verwerkt en deze
            beschikbaar stelt aan de Opdrachtgever, zodat de Opdrachtgever
            contact met u kan opnemen via telefoon of e-mail met betrekking tot
            de in de campagne beschreven dienst, service en/of producten.
          </li>
          <li>
            Deelname aan de campagne is op eigen risico. Re:publish aanvaardt
            geen aansprakelijkheid voor eventuele schade die voortvloeit uit de
            deelname aan de campagne, noch voor technische storingen, gebreken
            of vertragingen. Evenmin aanvaardt Re:publish aansprakelijkheid voor
            mogelijke problemen of gebreken die voortvloeien uit het gebruik van
            de campagnepagina(s) en/of het downloaden van enige componenten met
            betrekking tot de campagne.
          </li>
          <li>
            Re:publish behoudt zich het recht voor om te allen tijde en naar
            eigen inzicht deze voorwaarden te wijzigen of aan te passen tijdens
            de looptijd van de campagne, zonder nadelige gevolgen voor de
            deelnemer.
          </li>
          <li>
            Re:publish kan ook zonder opgaaf van reden de campagne beëindigen,
            wijzigen of aanpassen om moverende redenen, zonder enige
            verplichting tot schadevergoeding jegens de deelnemer.
          </li>
          <li>
            Indien een of meer bepalingen van deze voorwaarden nietig of
            ongeldig worden verklaard, heeft dit geen invloed op de geldigheid
            van de overige bepalingen van deze voorwaarden.
          </li>
          <li>
            Deze voorwaarden zijn onderworpen aan het Nederlands recht.
            Eventuele geschillen worden voorgelegd aan de bevoegde rechtbanken
            in Nederland.
          </li>
        </ul>
        <p>
          Voor vragen, klachten of opmerkingen over de campagne, kunt u contact
          opnemen via:
        </p>
        <b>Re:publish BV</b>
        <p class="mb-0">Weena 690, verdieping 23, kantoor 7</p>
        <p class="mb-0">312CN Rotterdam</p>
        <p class="mb-0">Nederland</p>
        <a href="mailto:info@republish.nl">info@republish.nl</a>
        <p>KVK: 85369780</p>
      </div>
      <!-- <div class="space-1 section-row-col-content-class-542326" style="">
        <h1 data-title="Disclaimer" id="disclaimer">Disclaimer</h1>
        <p>
          Solvari doet haar uiterste best om te voorkomen dat de informatie die
          op de websites wordt verstrekt fouten bevat. Deze garantie kunnen we
          helaas niet geven. Voor eventuele onjuistheden op onze websites is
          Solvari niet aansprakelijk. Heeft u foutieve informatie op onze
          websites gezien, dan stellen wij het op prijs als u ons daarvan op de
          hoogte stelt.<br />
        </p>
        <p>
          Alles wat op de websites van Solvari staat mag enkel gebruikt worden
          voor niet-commerciële doeleinden. Bezoekers en gebruikers van de
          websites van Solvari mogen zonder uitdrukkelijke schriftelijke
          toestemming van Solvari niks van de websites overnemen,
          vermenigvuldigen, verspreiden of op een andere manier ter beschikking
          stellen aan anderen. Solvari, of de rechthebbende, behoudt alle
          rechten, waaronder auteurs- en merkrechten en/of andere intellectueel
          eigendomsrechten, met betrekking tot alles wat op de websites van
          Solvari staat.
        </p>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Privacybeleid</h2>
        <p>Solvari B.V. (hierna Solvari), gevestigd aan</p>
        <p>Orteliuslaan 850,<br />3528BB Utrecht<br />Nederland</p>
        <p>
          is verantwoordelijk voor de verwerking van persoonsgegevens zoals
          weergegeven in dit privacybeleid.
        </p>
        <p>Contactgegevens:</p>
        <p>
          <a href="https://www.solvari.nl">https://www.solvari.nl<br /></a
          >Orteliuslaan 850,<br />3528BB Utrecht<br />Nederland
        </p>
        <p>+31 (0)20 262 6228</p>
        <p>
          Hesley Serné&nbsp;is de Functionaris Gegevensbescherming van Solvari.
          Zij is per e-mail te bereiken via
          <a href="mailto:fg@solvari.com">fg@solvari.com</a>.
        </p>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542328">
        <h2 data-title="Persoonsgegevens" id="persoonsgegevens">
          Persoonsgegevens die wij verwerken
        </h2>

        <p>
          Solvari verwerkt je persoonsgegevens doordat je gebruik maakt van onze
          diensten en/of omdat je deze gegevens zelf aan ons verstrekt.
        </p>
        <p>
          Offertes op maat maken kan alleen wanneer we over een minimale
          hoeveelheid persoonsgegevens beschikken. Vakspecialisten hebben die
          nodig om een offerte uit te brengen die geheel afgestemd is op je
          situatie. Als je Solvari of een van haar andere diensten gebruikt, dan
          gaan we er vanuit dat je dit begrijpt en de verwerking van
          persoonsgegevens goedkeurt.
        </p>
        <p>
          Hieronder vind je een overzicht van de persoonsgegevens die wij
          verwerken:
        </p>
        <p>
          <em
            >Gegevens die we alleen verzamelen als je een offerte-aanvraag
            doet</em
          >
        </p>

        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>
                <p>Voor- en achternaam&nbsp;</p>
                <p>Geslacht</p>
              </td>
              <td>
                <p>
                  We hechten veel waarde aan persoonlijke dienstverlening. Met
                  een voor- en achternaam maak je het mogelijk voor
                  vakspecialisten om je netjes aan te spreken.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Adresgegevens</p>
                <p>Straatnaam, huisnummer, postcode</p>
              </td>
              <td>
                <p>
                  Vakspecialisten gebruiken adresgegevens om de offerte zo goed
                  mogelijk af te stemmen op je situatie. Ze kunnen nadat ze
                  toestemming aan je hebben gevraagd bijvoorbeeld je woonadres
                  bezoeken om metingen uit te voeren. Of dat gebeurt, is per
                  situatie verschillend. <br /><br />Ook kunnen vakspecialisten
                  adresgegevens gebruiken om bij de gemeente te controleren op
                  lokale subsidiemogelijkheden.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Telefoonnummer</p>
                <p>Emailadres</p>
              </td>
              <td>
                <p>
                  Vakspecialisten zullen in de meeste gevallen telefonisch
                  contact opnemen. Bij geen gehoor sturen ze je een e-mail, SMS
                  of WhatsApp bericht.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Overige persoonsgegevens die je zelf aan ons verstrekt,
                  bijvoorbeeld in correspondentie en telefonisch.
                </p>
              </td>
              <td>
                <p>
                  Solvari levert een service door op te treden als tussenpersoon
                  voor consumenten en vakspecialisten.
                </p>
                <p>
                  Om die service aan beide kanten te garanderen, kan Solvari
                  extra informatie aan je aanvraag toevoegen die ze per mail of
                  telefonisch van je ontvangt.
                </p>
              </td>
            </tr>
            <tr>
              <td><p>Aanvraagspecifieke gegevens</p></td>
              <td>
                <p>
                  Dit zijn gegevens die over jouw woonsituatie gaan en zijn
                  belangrijk voor een effectieve dienstverlening door
                  vakspecialisten.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <em
            ><br />Gegevens die we verzamelen, ongeacht je een offerte-aanvraag
            doet</em
          ><br />
        </p>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td><p>Gegevens over jouw activiteiten op onze website</p></td>
              <td>
                <p>
                  Door Solvari of een van haar websites (zie bovenstaand) te
                  bezoeken, houden we standaard statistieken van het bezoek bij.
                </p>
                <p>
                  Denk daarbij aan de pagina’s die je bekijkt en hoe lang je op
                  de pagina’s blijft.
                </p>
                <p>
                  Deze standaard statistieken geeft Solvari een indruk van de
                  aantrekkelijkheid van de website en het gemak waarmee je
                  navigeert naar verschillende pagina’s. Dat is belangrijk om
                  onze dienstverlening beter op jou af te stemmen.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Gegevens over jouw surfgedrag over verschillende websites heen
                  (bijvoorbeeld omdat dit bedrijf onderdeel is van een
                  advertentienetwerk)
                </p>
              </td>
              <td>
                <p>
                  Hyperlinks zijn de digitale paden op het internet waarmee je
                  van A naar B gaat. Wanneer een website (noem het &nbsp;‘A’)
                  een link (een ‘pad’) van Solvari (noem het ‘B’) op zijn
                  website heeft geplaatst, bijvoorbeeld in een blog of
                  nieuwsartikel, en jij op de link klikt, dan bezoek je de
                  website van Solvari.
                </p>
                <p>
                  Wij zien dan in onze websitestatistieken dat jouw bezoek
                  afkomstig is van het blog of nieuwsartikel van website A
                  waarin onze link vermeld staat.
                </p>
              </td>
            </tr>
            <tr>
              <td><p>Internetbrowser en apparaat type</p></td>
              <td>
                <p>
                  Bij het ontwerp van een website moet je rekening houden met
                  verschillende apparaten en soorten browsers. Zo verkrijgen we
                  bijvoorbeeld inzicht in langzame laadtijden, foutmeldingen en
                  pagina’s die verkeerd worden ingeladen. Dankzij die informatie
                  kunnen we een goed werkende website houden.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542330" style="">
        <h3
          data-title="Bijzondere persoonsgegevens"
          id="bijzondere-persoonsgegevens"
        >
          Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
        </h3>
        <p>
          Onze website en/of dienst heeft niet de intentie gegevens te
          verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij
          ze toestemming hebben van ouders of voogd. We kunnen echter niet
          controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook
          aan betrokken te zijn bij de online activiteiten van hun kinderen, om
          zo te voorkomen dat er gegevens over kinderen verzameld worden zonder
          ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder
          die toestemming persoonlijke gegevens hebben verzameld over een
          minderjarige, neem dan contact met ons op via fg@solvari.com, dan
          verwijderen wij deze informatie.
        </p>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542331" style="">
        <h3 data-title="Doel" id="doel">
          Met welk doel en op basis van welke grondslag wij persoonsgegevens
          verwerken
        </h3>
        <p>
          Solvari B.V. verwerkt jouw persoonsgegevens voor de volgende doelen:
        </p>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong
                    >Het doel waarmee we persoonsgegevens verzamelen</strong
                  >
                </p>
              </td>
              <td>
                <p><strong>Wettelijke grondslag</strong></p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Je offerte-aanvraag te kunnen koppelen aan maximaal vier
                  bedrijven die in jouw regio de gevraagde werken uit kunnen
                  voeren.
                </p>
              </td>
              <td><p>Overeenkomst</p></td>
            </tr>
            <tr>
              <td>
                <p>
                  Je commerciële e-mails te kunnen sturen, bijvoorbeeld
                  nieuwsbrieven en bespaartips.
                </p>
              </td>
              <td><p>Toestemming</p></td>
            </tr>
            <tr>
              <td>
                <p>
                  Je te kunnen bellen, mailen, WhatsAppen of SMS-en indien dat
                  nodig is om onze dienstverlening uit te kunnen voeren,
                  bijvoorbeeld door je te mailen over de voortgang van je
                  offerte-aanvraag of je op de hoogte te houden van berichten
                  die gestuurd worden tussen jou en vakspecialisten.
                </p>
              </td>
              <td><p>Overeenkomst</p></td>
            </tr>
            <tr>
              <td>
                <p>
                  Een online omgeving aan te kunnen maken waar je de status van
                  je offerte-aanvraag kan bijhouden.
                </p>
              </td>
              <td>
                <p>Overeenkomst</p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  De pagina's die je op Solvari.nl bezoekt te analyseren, zodat
                  we je relevante producten of diensten kunnen aanbieden.
                </p>
              </td>
              <td><p>Toestemming</p></td>
            </tr>
          </tbody>
        </table>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542332" style="">
        <h3
          id="geautomatiseerde-besluitvorming"
          data-title="Geautomatiseerde besluitvorming"
        >
          Geautomatiseerde besluitvorming
        </h3>
        <p>
          Solvari B.V. neemt wel op basis van geautomatiseerde verwerkingen
          besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor
          personen. Het gaat hier om besluiten die worden genomen door
          computerprogramma's of -systemen, zonder dat daar een mens
          (bijvoorbeeld een medewerker van Solvari B.V.) tussen zit.
        </p>
        <p>
          <em>Waarom Solvari automatische besluitvorming inzet</em><br />Solvari
          heeft zelf een systeem ontwikkeld dat jouw offerte-aanvraag aan de
          juiste vakmannen koppelt. De koppeling die het systeem maakt, is een
          voorgestelde koppeling op basis van de gegevens die je in het
          formulier hebt ingevuld. Dat voorstel wordt altijd daarna door een
          medewerker van Solvari gecontroleerd voordat de offerte-aanvraag
          daadwerkelijk verstuurd wordt naar de bedrijven.
        </p>
        <p>
          <em>Onderliggende logica</em><br />Om een zo goed mogelijke koppeling
          voor een product of dienst te kunnen voorstellen, maakt het systeem
          gebruik van de door jou ingevulde adresgegevens. Op basis van de
          afstand van jouw woning tot het bedrijf, worden er bedrijven
          voorgesteld die de offerte-aanvraag in behandeling zouden kunnen
          nemen. Op die manier kan Solvari jou in contact laten komen met
          bedrijven uit jouw regio.
        </p>
        <p>
          <em>Belang</em><br />Een automatisch systeem is nodig, omdat handmatig
          op zoek gaan naar de juiste bedrijven een tijdrovende onderneming is
          gezien de hoeveelheid aanvragen die we dagelijks verwerken. Het gevolg
          van een automatisch systeem is dat Solvari jou sneller in contact kan
          brengen met bedrijven, waardoor je ook sneller je offertes kan
          ontvangen.
        </p>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542333" style="">
        <h3 id="bewaartijd" data-title="Bewaartijd">
          Hoe lang we persoonsgegevens bewaren
        </h3>
        <p>
          Solvari bewaart je persoonsgegevens niet langer dan strikt nodig is om
          de doelen te realiseren waarvoor je gegevens worden verzameld.
        </p>
        <p>
          Doordat Solvari echter zelf lastig kan inschatten wanneer de
          dienstverlening stopt, geven wij jou, als gebruiker, alle vrijheid om
          zelf te bepalen hoe lang Solvari jouw gegevens bewaart.
        </p>
        <p>
          Wij hanteren de volgende bewaartermijnen voor de volgende
          (categorieën) van persoonsgegevens:
        </p>

        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>
                <p><strong>Persoonsgegevens</strong></p>
              </td>
              <td>
                <p><strong>Bewaartermijn</strong></p>
              </td>
              <td>
                <p><strong>Reden</strong></p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Voornaam, achternaam, geslacht, telefoonnummer, e-mailadres,
                  straatnaam, huisnummer
                </p>
              </td>
              <td><p>21 dagen tot onbepaald</p></td>
              <td>
                <p>
                  De minimale bewaartermijn is 21 dagen, omdat dat de termijn is
                  dat bedrijven de mogelijkheid hebben om contact met je op te
                  nemen.
                </p>
                <p>
                  Als je ervoor kiest een account aan te maken waar je de status
                  van je offerte-aanvraag kan bijhouden, dan bewaart Solvari
                  deze persoonsgegevens tot het moment dat jij wilt dat we ze
                  verwijderen.
                </p>
                <p>
                  In jouw persoonlijke account kan je zelf op ieder gewenst
                  moment alle persoonsgegevens, inclusief je account,
                  verwijderen. Zo behoud je de controle.
                </p>
              </td>
            </tr>
            <tr>
              <td><p>Postcode</p></td>
              <td><p>21 dagen tot onbepaald</p></td>
              <td>
                <p>
                  De volledige postcode zoals je die in het formulier hebt
                  ingevuld, zal <strong>geanonimiseerd</strong> worden.
                </p>
                <p>
                  We behouden wel de eerste twee cijfers van de volledige
                  postcode voor de verbetering van onze dienstverlening.
                </p>
                <p>
                  De eerste twee cijfers van een postcode wijzen op de regio.
                  Door die veralgemening beschermen we jouw privacy.
                </p>
                <p><strong>Voorbeeld</strong>:</p>
                <p>1082 (Amsterdam Zuid) wordt</p>
                <p>10 (Regio Amsterdam)</p>
              </td>
            </tr>
          </tbody>
        </table>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542334" style="">
        <h3
          data-title="Delen van persoonsgegevens"
          id="delen-van-persoonsgegevens"
        >
          <br />Delen van persoonsgegevens met derden
        </h3>
        <p>
          Solvari deelt jouw persoonsgegevens met verschillende derden als dit
          noodzakelijk is voor het uitvoeren van de overeenkomst en om te
          voldoen aan een eventuele wettelijke verplichting. Concreet betekent
          dit dat we persoonsgegevens zullen delen met maximaal vijf
          vakspecialisten (bedrijven) die je een gratis offerte kunnen
          toesturen.
        </p>
        <p>
          Bedrijven die bij Solvari zijn aangesloten zijn verbonden aan de
          algemene voorwaarden, waarin staat dat ze zichzelf ertoe verplichten
          zorgvuldig om te gaan met de persoonsgegevens die ze van jou, via
          Solvari, ontvangen, in overeenstemming met de Wet Algemene Verordening
          Persoonsgegevens (AVG-wet).
        </p>
        <p>
          Solvari B.V. blijft verantwoordelijk voor deze verwerkingen. Daarnaast
          verstrekt Solvari B.V. jouw persoonsgegevens aan andere derden. Dit
          doen wij alleen met jouw nadrukkelijke toestemming.
        </p>
        <p><em>Derden aan wie we je persoonsgegevens delen</em></p>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>
                <p><strong>Naam</strong></p>
              </td>
              <td>
                <p><strong>Jurisdictie</strong></p>
              </td>
              <td>
                <p><strong>Doel</strong></p>
              </td>
              <td>
                <p><strong>Welke gegevens</strong></p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Nederlandse bedrijven</strong> met een rechtsvorm die
                  offerte-aanvragen behandelen (zie
                  <a href="/bedrijven-overzicht"
                    >https://www.solvari.nl/bedrijven-overzicht</a
                  >) voor producten en diensten die op de website van Solvari.nl
                  staan (zie
                  <a href="/producten">https://www.solvari.nl/producten</a>)
                </p>
              </td>
              <td><p>Nederland</p></td>
              <td>
                <p>
                  <strong
                    >Zodat je gratis offertes kan ontvangen voor je
                    offerte-aanvraag</strong
                  >.
                </p>
              </td>
              <td>
                <p>
                  <strong>Persoonsgegevens</strong> zoals in dit privacybeleid
                  vermeld staat onder ‘persoonsgegevens die wij verwerken’
                </p>
                <p>
                  Daarnaast verstrekken wij ook de<strong>
                    antwoorden op productspecifieke vragen</strong
                  >, bijvoorbeeld of je woning een spouwmuur heeft of waar het
                  vochtprobleem in huis zich voordoet.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Externe partijen</strong> die we inschakelen om
                  e-mails, WhatsApp of sms-berichten te kunnen versturen
                </p>
              </td>
              <td><p>Nederland</p></td>
              <td>
                <p>
                  Zodat we je op een veilige manier per e-mail, WhatsApp of SMS
                  op de hoogte kunnen houden over &nbsp;je aanvraag.
                </p>
                <p>
                  Zodat we je op een veilige manier persoonlijke bespaartips en
                  woonideeën kunnen toesturen.
                </p>
              </td>
              <td>
                <p>
                  <strong>Persoonsgegevens</strong> zoals in dit privacybeleid
                  vermeld staat onder ‘persoonsgegevens die wij verwerken’
                  &nbsp;
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542335" style="">
        <h3 id="cookies" data-title="Cookies">
          Cookies, of vergelijkbare technieken, die wij gebruiken
        </h3>
        <p>
          Solvari gebruikt functionele, analytische en tracking cookies. Een
          cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
          website wordt opgeslagen in de browser van je computer, tablet of
          smartphone. Bij het gebruik van onze website ga je akkoord met het
          plaatsen van deze cookies.
        </p>
        <p>
          Je kunt je afmelden voor cookies door je internetbrowser zo in te
          stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle
          informatie die eerder is opgeslagen via de instellingen van je browser
          verwijderen.
        </p>
        <p>
          Zie voor een toelichting: <br />
          <a
            href="https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/"
            target="_blank"
            >https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/</a
          >
        </p>
        <p>
          Solvari B.V. gebruikt functionele en analytische cookies. Deze zorgen
          ervoor dat de website naar behoren werkt en dat bijvoorbeeld jouw
          voorkeursinstellingen onthouden worden. Ze worden ook gebruikt om de
          website goed te laten werken en deze verder te kunnen optimaliseren.
          Daarnaast plaatsen we cookies die jouw surfgedrag bijhouden zodat we
          op maat gemaakte content en advertenties kunnen aanbieden.&nbsp;
        </p>
        <p>
          Zie <a href="/cookie-verklaring" title="cookie statement">hier</a> een
          overzicht van alle cookies en hoe je ze kan aanpassen.
        </p>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542336" style="">
        <h3 id="google-analytics" data-title="Google Analytics">
          Je sitebezoek bijhouden in Google Analytics<br />
        </h3>
        <p>
          Om de website van Solvari te kunnen blijven verbeteren, plaatsen we
          een cookie van Google Analytics in je browser (zie hierboven ‘google
          analytics tracking’). Ook daarin respecteert Solvari jouw privacy.
          Solvari heeft een verwerkersovereenkomst gesloten met Google en
          anonimiseert de gegevens die naar aanleiding van jouw websitebezoek
          worden verwerkt in Google Analytics. Het is daardoor onmogelijk om die
          gegevens naar jou persoonlijk te herleiden.
        </p>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542337" style="">
        <h3 data-title="Gegevens inzage" id="gegevens-inzage">
          Gegevens inzien, aanpassen, verwijderen of overdragen
        </h3>
        <p>
          Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of
          te verwijderen. Dit kun je zelf doen via de persoonlijke instellingen
          van jouw account. Daarnaast heb je het recht om je eventuele
          toestemming voor de gegevensverwerking in te trekken of bezwaar te
          maken tegen de verwerking van jouw persoonsgegevens door ons bedrijf
          en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je
          bij ons een verzoek kan indienen om de persoonsgegevens die wij van
          jou beschikken in een computerbestand naar jou of een ander, door jou
          genoemde organisatie, te sturen.
        </p>
        <p>
          <em>Je gegevens inzien en overdragen met een persoonlijk account</em
          ><br />Solvari weet zoveel als jij ons geeft. Jouw gegevens zullen
          bestaan uit een gedeelte persoonlijke gegevens en een gedeelte
          aanvraagspecifieke gegevens. Als je er na je offerte-aanvraag voor
          kiest om een persoonlijk account aan te maken, dan kan je op elk
          gewenst moment inzien welke gegevens we van jou hebben opgeslagen.
        </p>
        <p>
          Naast het recht op inzage is er recht op overdracht. Je kan via je
          persoonlijke account de gegevens die bij ons bekend zijn, digitaal
          naar je eigen computer downloaden. Daarmee maken we het je mogelijk om
          eenvoudig je gegevens bij een andere aanbieder aan te leveren.
        </p>
        <p>
          <em>Zelf je gegevens wijzigen met een persoonlijk account</em><br />Je
          hebt eveneens het recht om je persoonsgegevens te wijzigen,
          bijvoorbeeld omdat je per ongeluk een spelfout hebt gemaakt of omdat
          je verhuisd bent naar een andere woning. In je persoonlijke account
          kan je altijd je persoonsgegevens wijzigen, behalve je e-mailadres. De
          reden daarvan is dat we je e-mailadres gebruiken om je
          offerte-aanvraag aan te koppelen.
        </p>
        <p>
          <em
            >Zelf je persoonsgegevens verwijderen met een persoonlijk
            account</em
          ><br />Bij wet moet Solvari zeker weten dat het verzoek om inzage,
          wijziging of verwijdering door jou is ingediend. Om die reden heb je
          na de offerte-aanvraag de optie gekregen om een persoonlijk account
          aan te maken, zodat je zelf je eigen persoonsgegevens altijd kan
          beheren.
        </p>
        <p>
          Concreet betekent dit dat je zelf op elk gewenst moment de
          persoonsgegevens die wij hebben, kan inzien, wijzigen, overdragen of
          verwijderen. Wanneer je verzoekt je gegevens te verwijderen, dan
          zullen we een termijn van 21 dagen hanteren. De wettelijke termijn
          hiervoor is een maand. Je verzoek tot verwijdering indienen via je
          persoonlijke account, is daarom de snelste en veiligste manier.
        </p>
        <p>
          <em
            >Je gegevens inzien, aanpassen, verwijderen of overdragen zonder
            persoonlijk account</em
          ><br />Als je de voorkeur geeft dat wij persoonlijk je verzoek in
          behandeling nemen, dan is Solvari bij wet verplicht te vragen om
          een&nbsp;kopie van je identiteitsbewijs te sturen naar
          <a href="mailto:fg@solvari.com">fg@solvari.com</a>, om er zeker van te
          zijn dat het verzoek echt door jou is gedaan. Maak in deze kopie je
          pasfoto, MRZ (<em>machine readable zone</em>, de strook met nummers
          onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN)
          zwart. Dit ter bescherming van je privacy. &nbsp;Om aan je verzoek te
          voldoen (inzage, correctie, overdracht of verwijdering), hanteren wij
          de wettelijke verwerkingstermijn van een maand.
        </p>
        <p>
          <em>Autoriteit Persoonsgegevens</em><br />Solvari B.V. wil je er
          tevens op wijzen dat je de mogelijkheid hebt om een
          <a
            href="https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap"
            target="_blank"
            >klacht</a
          >
          in te dienen bij de nationale toezichthouder, de Autoriteit
          Persoonsgegevens.
        </p>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542338" style="">
        <h3 data-title="Beveiliging" id="beveiliging">
          Hoe wij persoonsgegevens beveiligen
        </h3>
        <p>
          Solvari neemt de bescherming van jouw gegevens serieus en neemt
          passende maatregelen om misbruik, verlies, onbevoegde toegang,
          ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
          Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn
          of er aanwijzingen zijn van misbruik, neem dan contact op met onze
          klantenservice of via fg@solvari.com.
        </p>
        <p><br /><br /></p>
        <p>
          <small class="terms-use"
            ><em>Dit privacybeleid is geüpdatet op 29 januari 2020</em></small
          >
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>
