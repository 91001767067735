<template>
  <div class="work">
    <b-container>
      <p class="work__sub-title mb-0">Nederlandsadvies.nl</p>
      <p class="work__title">Soorten dakwerken</p>
      <p class="text-center work__sub-title">
        Er zijn verschillende soorten dakwerken. Zo kun je je dak laten
        renoveren en opnieuw laten bedekken.
      </p>
      <b-row class="justify-content-around">
        <b-col
          v-for="(item, index) in items"
          :key="index"
          cols="12"
          md="5"
          class="h-100"
        >
          <WorkCard :item="item" />
        </b-col>
      </b-row>

      <!-- <b-row class="justify-content-center">
        <b-col cols="12" md="4">
          <b-button class="mt-5 w-100" @click="$emit('nextPage')">
            Start de WONINGSCAN</b-button
          >
        </b-col>
      </b-row> -->
    </b-container>
  </div>
</template>

<script>
import editIcon from "../../assets/imgs/dakwerkan-main.png";
import checkIcon from "../../assets/imgs/dakwerken-main2.png";
// import fileIcon from "../../assets/imgs/file.png";
import WorkCard from "../../components/dakwerken/WorkCard.vue";
export default {
  name: "WorkSection",
  components: { WorkCard },
  data() {
    return {
      items: [
        {
          step: "Dakrenovatie",
          description1: "Nieuwe dakpannen plaatsen",
          description2: "Isoleren vanaf de buitenzijde",
          description3: "Nieuwe dakbedekking plaatsen of vervangen",
          icon: editIcon,
        },
        {
          step: "Nieuwe dakbedekking",
          description1: "Geeft je woning een frisse uitstraling",
          description2: "In veel soorten en maten te verkrijgen",
          description3: "Een nieuw dak is onderhoudsvriendelijk",
          description4: "Beschermt je woning tegen alle weersinvloeden",
          icon: checkIcon,
        },
        // {
        //   step: "S",
        //   description:
        //     "Kies het beste aanbod en begin met besparen op airconditioning én je energierekening.",

        //   icon: checkIcon,
        // },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.step++;
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
.work {
  background: #ffff !important;
  padding-top: 100px;
  padding-bottom: 100px;

  &__sub-title {
    font-size: 16px;
    color: #1c1829;
    font-weight: 400;
    text-align: center;
  }

  &__title {
    font-size: 30px;
    color: #1c1829;
    font-weight: 800;
    text-align: center;
    line-height: 35px;
  }
  .btn {
    border-radius: 37px;
    // background-color: #1d1729;
    background-color: #ffcc01;
    padding: 10px;
    // font-size: 17px;
    font-size: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d1729;
    font-weight: 900;
    border: none !important;
  }

  @media (max-width: 769.99px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
</style>
