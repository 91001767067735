<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <!-- First section with 70% width -->
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          Wanneer wordt u eigenaar van de woning?
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <div class="check-item" @click="handleClick('5066')">
                  <b-form-radio value="5066"
                    >Nooit, ik huur de woning
                  </b-form-radio>
                </div>

                <div class="check-item mt-3" @click="handleClick('5069')">
                  <b-form-radio value="5069">Binnen 3 maanden</b-form-radio>
                </div>
                <div class="check-item mt-3" @click="handleClick('5072')">
                  <b-form-radio value="5072"
                    >Over meer dan 3 maanden</b-form-radio
                  >
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />
        <!-- <div
          class="d-flex w-100 justify-content-center align-items-center question-card__right-col-text mt-5"
        >
          <span>Walter gropiuserf 6, Amersfoort</span>
        </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";

export default {
  name: "SecondBQuestion",
  data() {
    return {
      selected: "",
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    question: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      if (val == "5066" || val == "5072") {
        this.$emit("nextQuestion", "2C");
      } else {
        this.$emit("nextQuestion", "3", val);
      }
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "2");
    },
  },
  watch: {
    selected() {
      if (this.selected == "5066" || this.selected == "5072") {
        this.$emit("nextQuestion", "2C");
      } else {
        this.$emit("nextQuestion", "3", this.selected);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/secondQuestion.png);
    background-size: 100% 100% !important;
  }
}
</style>
