<template>
  <div class="postcode">
    <b-container>
      <b-form @submit="handleNextPage">
        <b-row>
          <b-col cols="12" md="7" class="d-flex align-items-start">
            <img
              src="@/assets/imgs/arrow-right.png"
              class="img-fluid"
              alt="arrow"
            />
            <span class="ml-md-5 ml-4 postcode__text">
              Controleer nu hoeveel jij kan besparen met zonnepanelen!
            </span>
          </b-col>
          <b-col cols="12" md="5" class="mt-md-0 mt-3">
            <div class="row no-gutters">
              <div class="col-8">
                <b-form-input
                  v-model="postdata.zip"
                  @input="handleFieldInput('zip')"
                  placeholder="Postcode"
                  :state="zipValidation"
                  required
                ></b-form-input>
                <b-form-invalid-feedback v-if="!zipValidation">
                  Voer een geldige postcode in
                </b-form-invalid-feedback>
              </div>
              <div class="col-4">
                <b-form-input
                  v-model="houseNumber"
                  @input="handleFieldInput('house_number')"
                  placeholder="Huisnr."
                  class="ml-2"
                  :state="houseNumberValidation"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!houseNumberValidation">
                  Voer een geldige huisnummer in
                </b-form-invalid-feedback>
              </div>
            </div>

            <div class="my-3" v-if="setAddress">
              <b-alert show dismissible class="alert-danger">
                Combinatie komt niet overeen!
              </b-alert>
            </div>

            <b-button class="mt-3 w-100" type="submit" :disabled="loading">
              <span v-if="loading">Bezig met laden...</span>
              <span v-else>Start GRATIS DAKSCAN</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PostCodeForm",
  data() {
    return {
      zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/, // regex voor Nederlandse postcode
      houseRegex: /^[0-9]{1,4}[a-zA-Z]?$/, // regex voor huisnummers met optionele toevoeging (bijv. 10, 10a)
      houseNumber: "", // lokale state voor huisnummer
      setAddress: false,
      loading: false, // om de API-aanroepstatus bij te houden
    };
  },
  watch: {
    // Watcher voor postcode (zip)
    postdata: {
      handler(newVal) {
        if (this.zipRegex.test(newVal.zip)) {
          this.fetchMatchingHouseNumbers(); // Als de postcode valide is, haal bijpassende huisnummers op
        }
      },
      deep: true
    },
  },
  methods: {
    ...mapActions(["saveAddress"]),

    async fetchMatchingHouseNumbers() {
      const apiKey = "P6JTU52clKYjZca8";
      const apiUrl = `https://api.pro6pp.nl/v1/autocomplete?auth_key=${apiKey}&nl_sixpp=${this.postdata.zip}`;

      try {
        this.loading = true;
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (data && data.results && data.results.length > 0) {
          const matchingHouseNumbers = data.results.map(
            result => result.streetnumber || result.house_number || result.huisnummer
          );
          console.log("Bijpassende huisnummers:", matchingHouseNumbers);
        } else {
          console.log("Geen bijpassende huisnummers gevonden.");
        }
      } catch (error) {
        console.error("Error during API call:", error);
      } finally {
        this.loading = false;
      }
    },

    handleFieldInput(field) {
      if (field === 'zip') {
        this.$store.commit('SET_POSTCODE', this.postdata.zip);
      } else if (field === 'house_number') {
        this.$store.commit('SET_HOUSE_NUMBER', this.houseNumber);
        console.log(this.houseNumber)
      }
    },

    async validateAddress() {
      const apiKey = "P6JTU52clKYjZca8";
      const apiUrl = `https://api.pro6pp.nl/v1/autocomplete?auth_key=${apiKey}&nl_sixpp=${this.postdata.zip}&streetnumber=${this.houseNumber}`;

      try {
        this.loading = true;
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (data && data.results && data.results.length > 0) {
          const street = data.results[0].street; // Zorg ervoor dat dit de juiste key is
          const city = data.results[0].city; // Zorg ervoor dat dit de juiste key is

          // Sla het adres op in de Vuex-store met de juiste variabelen
          this.$store.dispatch("saveAddress", {
            zip: this.postdata.zip,
            house_number: this.houseNumber, // Gebruik hier houseNumber
            street: street,
            city: city,
          });

          // Ga naar de volgende pagina
          this.$emit("nextPage", "post");
        } else {
          // Toon foutmelding als het adres ongeldig is
          this.setAddress = true; 
          setTimeout(() => {
            this.setAddress = false;
          }, 2000);
        }
      } catch (error) {
        console.error("Error during API call:", error);
        this.setAddress = true;
        setTimeout(() => {
          this.setAddress = false;
        }, 2000);
      } finally {
        this.loading = false;
      }
    },

    handleNextPage(e) {
      e.preventDefault();

      if (this.isValidForm) {
        this.validateAddress();
      } else {
        this.setAddress = true;
        setTimeout(() => {
          this.setAddress = false;
        }, 2000);
      }
    },
  },
  computed: {
    ...mapGetters(["getErrorResponses"]),
    zipValidation() {
      if (this.postdata.zip === "" && !this.getErrorResponses.zip) {
        return null;
      }
      return this.zipRegex.test(this.postdata.zip);
    },
    houseNumberValidation() {
      if (this.houseNumber === "" && !this.getErrorResponses.house_number) {
        return null;
      }
      return this.houseRegex.test(this.houseNumber);
    },
    isValidForm() {
      return this.zipValidation && this.houseNumberValidation;
    },
  },
};
</script>

<style lang="scss" scoped>
.postcode {
  background: #ffe786;
  padding-top: 70px;
  padding-bottom: 70px;

  &__text {
    font-size: 36px;
    line-height: 40px;
    color: #1d1729;
    font-weight: 800;
  }

  .form-control {
    background-color: #ffffff;
    border: 1px solid #cccbcb;
    border-radius: 0px !important;
    color: #495057 !important;
  }

  .btn {
    border-radius: 37px;
    background-color: #1d1729;
    padding: 10px;
    font-size: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fefefe;
    font-weight: 900;
    border: none !important;

    &:hover,
    :active {
      background-color: #1d1729;
      color: #fefefe;
    }
  }

  @media (max-width: 1023.99px) {
    &__text {
      font-size: 25px;
      line-height: 30px;
    }
    .btn {
      font-size: 13px;
    }
    img {
      width: 30px;
    }
  }

  @media (max-width: 767.99px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 575.99px) {
    &__text {
      font-size: 20px;
      line-height: 25px;
    }
    .btn {
      font-size: 12px;
      letter-spacing: 1px;
    }
    img {
      width: 20px;
    }
  }
}
</style>
