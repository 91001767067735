<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          <!-- Wat heeft jouw voorkeur: zonnepanelen kopen of huren? -->
          <!-- Om hoeveel kozijnen gaat het? -->
          Dak isoleren: Wat voor dak heb je?
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <!-- <b-form-input
                placeholder="Vul hier het aantal kozijnen in."
                required
              ></b-form-input>
  
              <b-button class="mt-3 w-100" type="submit"> GA Verder </b-button> -->
            <!-- <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-2"
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="radio-sub-component"
                >
                  <div class="check-item" @click="handleClick('3875')">
                    <b-form-radio value="3875">Kopen </b-form-radio>
                  </div>
  
                  <div class="check-item mt-3" @click="handleClick('3878')">
                    <b-form-radio value="3878">Huren</b-form-radio>
                  </div>
                </b-form-radio-group>
              </b-form-group> -->
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <div class="check-item" @click="handleClick('4306')">
                  <b-form-radio>Plat dak </b-form-radio>
                </div>

                <div class="check-item mt-3" @click="handleClick('4235')">
                  <b-form-radio>Schuin dak</b-form-radio>
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />

        <!-- <div
            class="d-flex w-100 justify-content-center align-items-center question-card__right-col-text mt-5"
          >
            <span>Walter gropiuserf 6, Amersfoort</span>
          </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";

export default {
  name: "Third_Question",
  data() {
    return {
      selected: "",
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      // this.$emit("handleClick", val);
      this.$emit("nextQuestion", "analyze", val);
    },

    goToPreviousPage() {
      this.$emit("previousQuestion", "2");
    },
  },
  watch: {
    selected() {
      // this.$emit("nextQuestion", "4", this.selected);
      this.$emit("nextQuestion", "analyze");
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/secondQuestion.png);
    background-size: 100% 100% !important;
  }
}
</style>
