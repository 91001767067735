<template>
  <div>
    <div class="col-lg-12 section-row-col-class-433173" style="">
      <div class="space-1 section-row-col-content-class-508418" style="">
        <p>
          Dit privacybeleid is van toepassing op alle deelnemers van onderhavige
          campagne van <b>Solvari</b> (hierna te noemen: “Opdrachtgever”).
        </p>
        <p class="mt-3">
          De uitvoering van deze campagne wordt namens de Opdrachtgever verzorgd
          door Re:publish (hierna te noemen Re:publish of Organisator).
          Re:publish vind uw privacy is belangrijk. Re:publish vindt dat u erop
          moet kunnen vertrouwen dat uw persoonlijke informatie in
          overeenstemming met de hoogste ethische en juridische normen
          behandelen. In deze Privacy Policy wordt beschreven hoe persoonlijke
          informatie verzameld en verwerkt wordt, uiteraard rekening houdend met
          uw toestemming indien deze wettelijk is vereist.
        </p>
        <b>Verzamelde Gegevens</b>
        <p>
          Wij verwerken uw persoonsgegevens op basis van de door u verstrekte
          informatie en uw uitdrukkelijke toestemming bij het verzenden van het
          formulier. De persoonsgegevens die wij verwerken, worden door u
          ingevuld. Daarnaast registreren wij de volgende gegevens bij het
          verzenden van het formulier:
        </p>
        <ul>
          <li>Datum en tijd van aanmelding/registratie</li>
          <li>IP-adres</li>
          <li>Website van aanmelding/registratie</li>
        </ul>
        <p>
          Wij streven er naar geen gegevens te verzamelen van bezoekers jonger
          dan 18 jaar. Hoewel we ons best doen om dit te controleren, kunnen wij
          die niet altijd garanderen. Als u een vermoeden heeft dat wij gegevens
          hebben verzamelend van een persoon jonger dan 18 jaar, neem dan
          contact met ons op zodat wij deze gegevens kunnen verwijderen.
        </p>
        <b>Doeleinden en Gegevensverwerking</b>
        <p>Wij verzamelen bovengenoemde contactgegevens om:</p>

        <ul>
          <li>
            Om telefonisch, per e-mail of per post contact met u op te nemen met
            over producten of diensten met betrekking tot onderhavige campagne;
          </li>
          <li>U marketingcommunicatie te sturen, indien u hiermee instemt.</li>
          <li>
            Indien van toepassing: om prijswinnaars te selecteren en informeren
            bij het uitreiken van prijzen.
          </li>
        </ul>
        <b class="mt-3">Rechtsgrondslag</b>
        <p>Wij verwerken uw gegevens op basis van uw toestemming.</p>

        <b class="mt-2">Hoe worden gegevens verzameld:</b>
        <p>
          Uw gegevens worden verzameld via online formulieren op onze
          campagnepagina.
        </p>
        <b>Delen van Gegevens</b>
        <p>
          Vergaarde persoonlijke informatie zal alleen door Re:publish en
          Opdrachtgever gebruikt worden voor de hierboven omschreven doeleinden.
          Opdrachtgever kan, indien nodig, een derde partij inschakelen om uw
          persoonsgegevens te verwerken in overeenstemming met de eerder
          genoemde doeleinden, bijvoorbeeld een callcenter of marketingbureau,
          zodat zij contact met u kunnen opnemen. Hoe de Opdrachtgever met uw
          gegevens omgaat, is vastgelegd in de privacyverklaring van de
          betreffende Opdrachtgever.
        </p>
        <p>
          Re:publish verstrekt persoonlijke informatie niet aan
          niet-geautoriseerde derden.
        </p>
        <b>Bewaartermijn</b>
        <p>
          Re:publish bewaart uw gegevens zolang als nodig is voor de doeleinden
          waarvoor ze zijn verzameld.
        </p>
        <b>Recht van Betrokkenen</b>
        <p>
          U heeft het recht om uw gegevens in te zien, te corrigeren of te
          verwijderen. Neem contact met ons op voor verzoeken met betrekking tot
          uw gegevens. Ook heeft u het recht om op elk moment uw toestemming
          voor gegevensverwerking in te trekken. U kunt dergelijk verzoek
          schriftelijk indienen bij de Opdrachtgever.
        </p>
        <b>Cookies en Tracking</b>
        <p>
          Bij uw bezoek aan onze website is het van belang om bepaalde
          informatie te verzamelen om de verbinding soepel te laten verlopen.
          Wanneer u een webpagina opvraagt, dient de webserver te weten waar de
          pagina naartoe moet worden gestuurd. Hiervoor wordt gebruik gemaakt
          van uw IP-adres, dat automatisch wordt toegewezen door uw
          internetprovider telkens wanneer u online gaat. Dit IP-adres wordt
          gebruikt om uw computer te identificeren.
        </p>
        <p>
          Re:publish kan persoonlijke gegevens, zoals uw Internet Protocol (IP)
          adres, opslaan wanneer u onze website(s) bezoekt. Wij behandelen deze
          informatie in overeenstemming met de geldende privacywetgeving. Deze
          gegevens worden uitsluitend gebruikt voor het beantwoorden van uw
          vragen, het analyseren van het gedrag van bezoekers op onze website en
          voor marketingdoeleinden.
        </p>

        <i>Wat is een cookie dan precies?</i>
        <p>
          Een cookie is een eenvoudig klein bestandje dat met de pagina's van
          onze website wordt meegestuurd en door uw browser op de harde schijf
          van uw computer wordt opgeslagen. De informatie die in deze cookies
          wordt opgeslagen, kan bij een volgend bezoek weer naar onze servers
          worden teruggestuurd.De gegevens die wij via deze cookies verkrijgen,
          stellen ons in staat om u aanbiedingen en advertenties te tonen die
          aansluiten bij uw interesses.
        </p>

        <b>Externe Links</b>
        <p>
          Onze website kan links naar andere websites bevatten. We zijn niet
          verantwoordelijk voor het privacybeleid of de inhoud van deze andere
          websites. We raden u aan om de privacyverklaringen op deze websites te
          lezen.
        </p>

        <b>Wijzigingen in het Beleid</b>
        <p>
          Dit beleid kan worden bijgewerkt in verband met veranderende
          wetgeving. Re:publish behoudt zich het recht voor om dit op elk moment
          en zonder voorafgaande kennisgeving te kunnen doen. Bekijk regelmatig
          ons privacybeleid voor eventuele wijzigingen.
        </p>
        <b>Juridische Basis en Rechtsgebied:</b>
        <p>
          Dit privacybeleid is onderworpen aan het Nederlands recht. Eventuele
          geschillen worden voorgelegd aan de bevoegde rechtbanken in Nederland.
        </p>
        <b>Re:publish BV</b>
        <p class="mb-0">Weena 690, verdieping 23, kantoor 7</p>
        <p class="mb-0">312CN Rotterdam</p>
        <p class="mb-0">Nederland</p>
        <a href="mailto:info@republish.nl">info@republish.nl</a>
        <p>KVK: 85369780</p>
      </div>
      <!-- <div class="space-1 section-row-col-content-class-508418" style="">
        <h1>Gebruikersvoorwaarden</h1>
        <p>Versie: 16 mei 2022</p>
        <h2>Belgischadvies is in samenwerking met Solvari.</h2>
        <p>
          Ben je op zoek naar een betrouwbare vakman actief in je buurt? Dan ben
          je bij Solvari aan het juiste adres. Via ons platform vind je snel en
          eenvoudig de juiste specialist om je woning te verduurzamen en te
          (ver)bouwen. Wil je gebruik maken van ons platform? In deze algemene
          gebruiksvoorwaarden leggen we uit hoe Solvari werkt en wat we van jou
          verwachten.
        </p>
        <hr class="my-1 mt-7" />
        <h2>Hoe werkt Solvari?</h2>
        <p>
          Een project in je woning gepland? Via Solvari kom je vrijblijvend in
          contact met meerdere vakmannen die actief zijn in je buurt. Het
          principe is eenvoudig: jij doet een aanvraag via ons platform en wij
          matchen je met verschillende vakmannen voor jouw klus. <br /><br />
          Voor alle duidelijkheid: Solvari is enkel een platform ('prikbord') om
          consumenten en vakmannen met elkaar in contact te brengen. Wij zijn
          dus verder niet betrokken bij de eventuele overeenkomst die je sluit
          met een vakman. Dit gaat helemaal buiten ons platform om. Je bent
          uiteraard niet verplicht om samen te werken met 1 van de vakmannen met
          wie we jou matchen. Jij kiest helemaal zelf of je de klus aan 1 van
          deze vakmannen uitbesteedt en onder welke voorwaarden of niet.
        </p>
        <hr class="my-1 mt-7" />
        <h2>Eenvoudig, gratis en vrijblijvend</h2>
        <p>
          Solvari brengt je als woningeigenaar volledig gratis en vrijblijvend
          in contact met geschikte vakmannen voor jouw klus. Hoe dat mogelijk
          is? Heel eenvoudig, wij helpen namelijk ook vakmannen door hen toegang
          te verlenen tot het platform en ze daarmee potentiele klanten aan te
          leveren. En zij betalen Solvari voor de contactgegevens die
          geinteresseerde consumenten ons bezorgen.<br /><br />
          Op die manier zorgt Solvari voor gelukkige consumenten en vakmannen.
          Jij komt als consument vrijblijvend in contact met vakmannen en die
          kunnen op hun beurt sneller nieuwe klanten vinden.
        </p>
        <hr class="my-1 mt-7" />
        <h2>Jouw klusaanvraag in 3 stappen</h2>
        <p>
          Hoe verloopt een klusaanvraag via Solvari nu precies? Het gaat om de
          volgende 3 stappen:
        </p>
        <h3>1. Je geeft info over jouw project door.</h3>
        <p>
          Via de websites van de Solvari groep vul je informatie rond jouw
          woningproject in. Hoe specifieker je aanvraag, hoe concreter het
          voorstel dat je ontvangt van de vakmannen. Naast de klusinfo zelf geef
          je ook je contact- en adresgegevens door. Zo kan Solvari die bezorgen
          aan de juiste vakmannen in jouw regio.<br /><br />
          Heb je alle gegevens ingevuld? Dan vragen we je ook om in te stemmen
          met onze algemene gebruiksvoorwaarden.
        </p>
        <h3>2. Solvari gaat voor jou aan de slag.</h3>
        <p>
          Solvari selecteert voor jou maximaal 3 vakmannen die aangesloten zijn
          op ons platform. Zij ontvangen jouw aanvraag en contact- en
          adresgegevens. Op basis daarvan hebben zij al een goed beeld van de
          uit te voeren klus.
        </p>
        <h3>3. De vakmannen nemen contact met je op.</h3>
        <p>
          Ten slotte nemen de vakmannen contact met je op om je project te
          bespreken. Het is de bedoeling dat zij snel reageren, maar dat kan
          Solvari niet garanderen. Krijg je geen telefoontje of mailtje? Laat
          het ons dan zeker weten, zodat we jou kunnen helpen.<br /><br />
          Je kan de klantenservice van Solvari bereiken door te bellen naar +31
          20 262 6228 of door te mailen naar
          <a href="MAILTO:service@solvari.com">service@solvari.com</a>.
        </p>
        <hr class="my-1 mt-7" />
        <h2>Kan iedereen een vakman van Solvari worden?</h2>
        <p>
          Het team van Solvari werkt dagelijks aan het platform om jou zo snel
          en eenvoudig mogelijk in contact te brengen met betrouwbare, geschikte
          vakmannen. We kunnen echter niet garanderen dat elke vakman dezelfde
          kwaliteit levert. Niettemin is dat wel onze ambitie. Daarom screent
          Solvari geinteresseerde vakmannen grondig met een telefonisch
          interview, reviews van consumenten en met beschikbare openbare
          bronnen.<br /><br />
          Ook jij kan je steentje bijdragen aan de monitoring van onze
          vakmannen. Hoe? Door feedback te geven en een review van de gekozen
          vakman te plaatsen op onze website.
        </p>
        <hr class="my-1 mt-7" />
        <h2>Do's en don'ts bij de keuze van een vakman</h2>
        <p>
          Heeft Solvari je gematcht met 2 a 3 geschikte vakmannen actief in je
          buurt? Dan is het aan jou om hun offertes gericht te vergelijken en de
          juiste keuze te maken. Maar hoe kies je nu de meest geschikte vakman
          voor je project? Enkele aandachtspunten op een rij:
        </p>
        <ul>
          <li>
            <strong>Prijs</strong>: de eindprijs voor jouw klus kan variëren van
            vakman tot vakman. Leg dus zeker grondig alle kostenposten naast
            elkaar.
          </li>
          <li>
            <strong>Offerte</strong>: het is mogelijk dat niet alle vakmannen
            dezelfde werkzaamheden opnemen in hun offerte. Ga dus zeker na welke
            werkzaamheden inbegrepen zijn in de prijs en welke niet.
          </li>
          <li>
            <strong>Garantie</strong>: met een vakman werken betekent ook
            financiële bescherming. Toch kunnen garantietermijnen op
            werkzaamheden verschillen. Let hier dus zeker op als je offertes
            vergelijkt.
          </li>
          <li>
            <strong>Adviezen</strong>: met welke materialen werkt een vakman en
            waarom? Hoe wil hij precies te werk gaan? Je legt de adviezen van de
            vakmannen het best naast elkaar om een weloverwogen keuze te maken.
          </li>
          <li>
            <strong>Reviews</strong>: zeker zijn van de kwaliteit van een
            vakman? Zoek dan zeker naar online referenties en reviews. Vraag de
            vakmannen ook naar eerdere projecten. Een trotse specialist toont je
            met plezier voorbeelden van eerdere realisaties.
          </li>
          <li>
            <strong>Brancheverenigingen</strong>: vind je het belangrijk dat een
            vakman is aangesloten bij bepaalde (branche)verenigingen? Vraag dit
            dan expliciet na bij een vakman en controleer het zelf ook.
          </li>
          <li>
            <strong>Aanbetaling</strong>: de aanbetaling van een product is
            volgens de wet maximaal 50% van het aankoopbedrag. Vraagt een vakman
            om een groter bedrag? Dan hoef je daarmee niet akkoord te gaan.
          </li>
        </ul>
        <hr class="my-1 mt-7" />
        <h2>Reviews zijn meer dan welkom!</h2>
        <p>
          Heb je een klus laten uitvoeren? Plaats dan zeker een review op onze
          website. Zo help je niet alleen andere consumenten, maar ook Solvari
          zelf. Deze reviews zijn namelijk 1 van de manieren om na te gaan of
          een vakman aangesloten mag blijven op ons platform.<br /><br />
        </p>
        <p>
          Wil je jouw ervaringen delen? Super! Volg hierbij de volgende regels:
        </p>
        <ul>
          <li>Schrijf altijd vanuit je eigen ervaringen.</li>
          <li>Wees eerlijk, vriendelijk en netjes.</li>
          <li>Gebruik geen grof en ongepast taalgebruik.</li>
          <li>Je kan maar 1 keer een review plaatsen over een vakman.</li>
        </ul>
        <p>
          <strong>BELANGRIJK:</strong> voldoet je review aan deze regels? Dan
          plaatsen we die na een korte check online. Het kan zijn dat we contact
          met je opnemen bij eventuele onduidelijkheden. Uiteraard is een
          negatieve ervaring geen reden om een review niet online te plaatsen.
          Want daarmee help je ook andere consumenten.
        </p>
        <hr class="my-1 mt-7" />
        <h2>En hoe zit het met foto's?</h2>
        <p>
          Bij het plaatsen van een review kan je ook foto's toevoegen. Hou
          daarbij wel de volgende regels in gedachten:
        </p>
        <ul>
          <li>
            Plaats alleen foto's van je eigen woning die je zelf hebt gemaakt
            met je eigen camera.
          </li>
          <li>Zorg dat er geen personen staan op de foto's.</li>
          <li>
            Plaats alleen foto's die betrekking hebben op de review. Door de
            foto's te plaatsen verklaar je auteursrechthebbende te zijn. Ben je
            dit niet en plaats je andermans foto? Dan kunnen wij eventuele
            schade op jou verhalen.
          </li>
        </ul>
        <p>
          Wil je een geplaatste review verwijderen of aanpassen? Neem dan gewoon
          even contact op met onze klantenservice via
          <a href="MAILTO:service@solvari.com">service@solvari.com</a> of
          telefoonnummer +31 (0) 20 26 262 28.
        </p>
        <hr class="my-1 mt-7" />
        <h2>Heb je klachten of opmerkingen?</h2>
        <p>
          Consumenten en vakmannen blij maken.. Daar gaan we elke dag voor bij
          Solvari. Ging er onverhoopt toch iets mis tijdens jouw project? Heb je
          klachten over het eindresultaat of over je gekozen vakman? Of heb je
          schade geleden? De vakman is verantwoordelijk voor de juiste
          uitvoering van de werkzaamheden die je bent overeengekomen. Je dient
          dan ook de vakman aan te spreken op de kwaliteit van de werkzaamheden.
          Als het niet lukt om met de vakman tot een oplossing te komen,
          vernemen wij dit graag van je. Neem dan contact op met onze
          klantenservice via
          <a href="MAILTO:service@solvari.com">service@solvari.com</a> of
          telefoonnummer +31 (0) 20 26 262 28.
        </p>
        <p>
          Solvari neemt elke klacht serieus en wij blijven werken aan de
          betrouwbaarheid van ons platform. Bij twijfel over een vakman starten
          we een onderzoek en nemen we de gepaste maatregelen. Indien nodig
          beeindigen we de samenwerking met de vakman in kwestie.
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
};
</script>
