<template>
  <div>

  <div class="solar mobiel">
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center flex-column"
        >
          <p class="solar__title-small mb-0">Zonnepanelen</p>
          <p class="solar__title">Bespaar en verdien slim met zonnepanelen</p>
          <p class="solar__description mt-2 mt-md-3 text-justify">
            Het gemiddelde rendement op zonnepanelen is vergelijkbaar met een
            rente van 5% op je spaarrekening. Met zonnepanelen bespaar je niet
            alleen op je energierekening, maar verdien je ook nog eens geld op
            de lange termijn. Wij zorgen ervoor dat je de beste deal krijgt bij
            de aanschaf van jouw zonnepanelen, zodat je zo veel mogelijk
            bespaart. Laat de zon voor jou werken en start vandaag nog met
            besparen en verdienen!
          </p>
          <p class="solar__description">
            Met de energiemarkt van vandaag kan het bedrag dat jij
            <strong> elke maand </strong>
            bespaart oplopen tot <strong> enkele honderden euro's.</strong>
          </p>
        </b-col>
        <b-col cols="12" md="1"></b-col>
        
        <b-col
          cols="12"
          md="5"
          class="mt-md-0 mt-2 d-flex align-items-center justify-content-start position-relative"
        >
          <img
            src="../../assets/imgs/solar1.png"
            class="img-flud"
            alt="Solar Image"
            width="100%"
            height="auto"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>







  <div class="solar desktop">

    <div class="container-sectie-2" style="display: inline-flex;">

    <div class="container-links-afbeelding" style="width: 30%; margin-left: 10%">
      <img
      src="../../assets/imgs/solar1.png"
      class=""
      alt="Solar Image"
      width="100%"
      height="auto"
    />
    </div>

    <div class="container-rechts-text" style="width: 50%; margin-left: 11%; padding-right: 12%; margin-top: 5%">
      <p class="solar__title-small mb-0">Zonnepanelen</p>
      <p class="solar__title">Bespaar en verdien slim met zonnepanelen</p>
      <p class="solar__description mt-2 mt-md-3 text-justify">
        Het gemiddelde rendement op zonnepanelen is vergelijkbaar met een
        rente van 5% op je spaarrekening. Met zonnepanelen bespaar je niet
        alleen op je energierekening, maar verdien je ook nog eens geld op
        de lange termijn. Wij zorgen ervoor dat je de beste deal krijgt bij
        de aanschaf van jouw zonnepanelen, zodat je zo veel mogelijk
        bespaart. Laat de zon voor jou werken en start vandaag nog met
        besparen en verdienen!
      </p>
      <p class="solar__description">
        Met de energiemarkt van vandaag kan het bedrag dat jij
        <strong> elke maand </strong>
        bespaart oplopen tot <strong> enkele honderden euro's.</strong>
      </p>
    </div>
  </div>







    <!-- <b-container>
      <b-row style="flex-direction: row-reverse;">
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center flex-column"

        >
          <p class="solar__title-small mb-0">Zonnepanelen</p>
          <p class="solar__title">Bespaar en verdien slim met zonnepanelen</p>
          <p class="solar__description mt-2 mt-md-3 text-justify">
            Het gemiddelde rendement op zonnepanelen is vergelijkbaar met een
            rente van 5% op je spaarrekening. Met zonnepanelen bespaar je niet
            alleen op je energierekening, maar verdien je ook nog eens geld op
            de lange termijn. Wij zorgen ervoor dat je de beste deal krijgt bij
            de aanschaf van jouw zonnepanelen, zodat je zo veel mogelijk
            bespaart. Laat de zon voor jou werken en start vandaag nog met
            besparen en verdienen!
          </p>
          <p class="solar__description">
            Met de energiemarkt van vandaag kan het bedrag dat jij
            <strong> elke maand </strong>
            bespaart oplopen tot <strong> enkele honderden euro's.</strong>
          </p>
        </b-col>


        <b-col cols="12" md="1"></b-col>
        
        <b-col
          cols="12"
          md="5"
          class="mt-md-0 mt-2 d-flex align-items-center justify-content-start position-relative"
        >
          <img
            src="../../assets/imgs/solar1.png"
            class="img-fluid"
            alt="Solar Image"
            width="100%"
            height="auto"
            style="position: relative;
            left: -30%;"
          />
        </b-col>
      </b-row>
    </b-container> -->



  </div>



</div>

</template>

<script>
export default {
  name: "SolarPanel",
};
</script>
<style lang="scss" scoped>


@media (min-width: 499px) {
  .mobiel {
    display: none;
  }
}


@media (max-width: 499px) {
  .desktop {
    display: none;
  }

  .container-sectie-2 {
    width: 100%;
    display: inline-flex;
  }

  .container-links-afbeelding {
    width: 50%!important;
  }



  .img-fluid {
    width: 50%;
  }

}


.solar {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #ffffff;

  &__title-small {
    font-size: 14px;
    color: #ffcc01;
    font-weight: 600;
  }

  &__title {
    font-size: 35px;
    color: #1d1729;
    font-weight: 700;
    line-height: 40px;
  }

  &__description {
    font-size: 16px;

    letter-spacing: 2px;
    // line-height: 33px;
    line-height: 30px;
    color: #1d1729;
    font-weight: 600;
  }

  &__panel {
    &-text {
      position: absolute;
      text-align: center;
      right: 44px;
      top: 88px;

      &-title {
        // font-size: 36px;
        font-size: 25px;

        color: #1d1729;
        font-weight: 900;
      }

      &-sub-title {
        // font-size: 16px;
        font-size: 12px;
        color: #1d1729;
        font-weight: 500;
      }
    }

    // &-text > p {
    //   font-size: calc(1vw + 1vh);
    //   margin: 0;
    // }
  }

  @media (max-width: 1199.99px) {
    &__title-small {
      font-size: 14px;
    }

    &__title {
      font-size: 35px;
      line-height: 40px;
    }

    &__description {
      font-size: 16px;
      line-height: 25px;
    }
  }
  @media (max-width: 992.99px) {
    &__title-small {
      font-size: 13px;
    }

    &__title {
      font-size: 30px;
    }

    &__description {
      font-size: 14px;
    }
  }
  @media (max-width: 767.99px) {
    padding-top: 30px;
    padding-bottom: 30px;
    &__title-small {
      font-size: 12px;
    }

    &__title {
      font-size: 25px;
      line-height: 30px;
    }

    &__description {
      font-size: 12px;
      line-height: 20px;
    }
  }
  @media (max-width: 577.99px) {
    &__title-small {
      font-size: 11px;
    }

    &__title {
      font-size: 20px;
      line-height: 20px;
    }

    &__description {
      font-size: 11px;
      line-height: 15px;
    }
  }
}
</style>
