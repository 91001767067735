import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import IsolateHome from "../views/IsolateHome.vue";
import LaadpalenHome from "../views/LaadpalenHome.vue";
import Zonnepanelen from "../views/Zonnepanelen.vue";

import ThankYou from "../views/ThankYou.vue";
import ThankYouIsolate from "../views/ThankYouIsolate.vue";
import ThankYouLaadpalen from "../views/ThankYouLaadpalen.vue";
import ThankYouZonnepanelen from "../views/ThankYouZonnepanelen.vue";
import Airconditioning from "../views/Airconditioning.vue";
import AirconditioningThankYou from "../views/AirconditioningThankyou.vue";
import Dakwerken from "../views/Dakwerken.vue";
import ThankYouDakwerken from "../views/ThankYouDakwerken.vue";

import Thuisbattarij from "../views/Thuisbattarij.vue";
import ThankYouThuisbattarij from "../views/ThankYouThuisbattarij.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Zonnepanelen,
    redirect: "/zonnepanelen",
  },
  {
    path: "/kunststofkozijnen",
    name: "Home",
    component: Home,
  },
  {
    path: "/laadpalen",
    name: "Home",
    component: LaadpalenHome,
  },
  {
    path: "/laadpalen/bedankt",
    name: "Bedankt",
    component: ThankYouLaadpalen,
  },
  {
    path: "/isolatie",
    name: "Home",
    component: IsolateHome,
  },
  {
    path: "/bedankt",
    name: "Bedankt",
    component: ThankYou,
  },
  {
    path: "/isolatie/bedankt",
    name: "Thanks",
    component: ThankYouIsolate,
  },
  {
    path: "/zonnepanelen",
    name: "Home",
    component: Zonnepanelen,
  },
  {
    path: "/zonnepanelen/bedankt",
    name: "Bedankt",
    component: ThankYouZonnepanelen,
  },
  {
    path: "/airconditioning",
    name: "Airconditioning",
    component: Airconditioning,
  },
  {
    path: "/airconditioning-bedankt",
    name: "AirconditioningBedankt",
    component: AirconditioningThankYou,
  },
  {
    path: "/dakwerken",
    name: "Dakwerken",
    component: Dakwerken,
  },
  {
    path: "/dakwerken-bedankt",
    name: "ThankYouDakwerken",
    component: ThankYouDakwerken,
  }, {
    path: "/thuisbatterij",
    name: "Thuisbatterij",
    component: Thuisbattarij,
  },
  {
    path: "/thuisbatterij-bedankt",
    name: "Thuisbatterij-Bedankt",
    component: ThankYouThuisbattarij,
  },
  {
    path: "/about",
    name: "About",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
