<template>
  <div class="solar">
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center flex-column"
        >
          <p class="solar__title-small mb-0">Laadpalen</p>
          <p class="solar__title">
            Bespaar met een laadpaal:<br />
            eigen stroom is goedkoper dan die van een openbare laadpaal.
          </p>
          <p class="solar__description mt-2 mt-md-3 text-justify">
            Je kan ervoor kiezen om een laadpaal aan huis te laten installeren.
            Hierbij geniet je van het feit dat je je nooit zorgen hoeft te maken
            dat iemand anders de oplaadpaal al in gebruik heeft genomen.
          </p>
          <p class="solar__description mt-2 mt-md-3 text-justify">
            Ook kan je je auto sneller en veilig opladen op elk moment dat je
            deze niet nodig hebt. Tenslotte is het voordeliger om je eigen
            stroom te gebruiken dan bij een openbare oplaadpaal.
          </p>
          <p class="solar__description mt-2 mt-md-3 text-justify">
            Zeker interessant als je bijvoorbeeld ook zonnepanelen hebt!
          </p>
        </b-col>
        <b-col cols="12" md="1"></b-col>
        <b-col
          cols="12"
          md="5"
          class="mt-md-0 mt-2 d-flex align-items-center justify-content-start position-relative"
        >
          <img
            src="@/assets/imgs/middle.jpg"
            class="img-fluid"
            alt="Solar Image"
            width="100%"
            height="auto"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SolarPanel",
};
</script>
<style lang="scss" scoped>
.solar {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #ffffff;

  &__title-small {
    font-size: 14px;
    color: #ffcc01;
    font-weight: 600;
  }

  &__title {
    font-size: 35px;
    color: #1d1729;
    font-weight: 700;
    line-height: 40px;
  }

  &__description {
    font-size: 16px;

    letter-spacing: 2px;
    // line-height: 33px;
    line-height: 30px;
    color: #1d1729;
    font-weight: 600;
  }

  &__panel {
    &-text {
      position: absolute;
      text-align: center;
      right: 44px;
      top: 88px;

      &-title {
        // font-size: 36px;
        font-size: 25px;

        color: #1d1729;
        font-weight: 900;
      }

      &-sub-title {
        // font-size: 16px;
        font-size: 12px;
        color: #1d1729;
        font-weight: 500;
      }
    }

    // &-text > p {
    //   font-size: calc(1vw + 1vh);
    //   margin: 0;
    // }
  }

  @media (max-width: 1199.99px) {
    &__title-small {
      font-size: 14px;
    }

    &__title {
      font-size: 35px;
      line-height: 40px;
    }

    &__description {
      font-size: 16px;
      line-height: 25px;
    }
  }
  @media (max-width: 992.99px) {
    &__title-small {
      font-size: 13px;
    }

    &__title {
      font-size: 30px;
    }

    &__description {
      font-size: 14px;
    }
  }
  @media (max-width: 767.99px) {
    padding-top: 30px;
    padding-bottom: 30px;
    &__title-small {
      font-size: 12px;
    }

    &__title {
      font-size: 25px;
      line-height: 30px;
    }

    &__description {
      font-size: 12px;
      line-height: 20px;
    }
  }
  @media (max-width: 577.99px) {
    &__title-small {
      font-size: 11px;
    }

    &__title {
      font-size: 20px;
      line-height: 20px;
    }

    &__description {
      font-size: 11px;
      line-height: 15px;
    }
  }
}
</style>
