<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <!-- First section with 70% width -->
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">Wat wil je isoleren?</p>
        <div class="d-flex align-items-center" v-if="ans === '4226'">
          <img src="@/assets/imgs/info.jpg" class="info__img" alt="" />
          <p class="mb-0 mx-2 font-weight-bold">
            Vanaf 1991 zijn spouwmuren altijd geïsoleerd.
          </p>
        </div>
        <!-- <b-form @submit="handleNextQuestion">
          <div class="row no-gutters">
            <div class="col-md-10">
              <b-form-input
                placeholder="Vul hier het aantal kozijnen in"
                type="text"
                v-model="value"
                :state="value ? houseNumberValidation : null"
                required
              ></b-form-input>
              <b-form-invalid-feedback v-if="!houseNumberValidation">
                Voer een geldige aantal bewoners in
              </b-form-invalid-feedback>
              <b-button class="mt-3 w-100" type="submit"> GA VERDER </b-button>
            </div>
          </div>
        </b-form> -->
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-2"
            v-model="selected"
            :aria-describedby="ariaDescribedby"
            name="radio-sub-component"
          >
            <b-col xl="9" cols="12" v-if="ans == '4214'">
              <FifthAQuestion
                @handleClick="handleNextClick"
                @handleSelected="handleSelected"
              />
            </b-col>

            <b-col xl="9" cols="12" v-if="ans == '4217'">
              <FifthBQuestion
                @handleClick="handleNextClick"
                @handleSelected="handleSelected"
              />
            </b-col>

            <b-col xl="9" cols="12" v-if="ans == '4220'">
              <FifthCQuestion
                @handleClick="handleNextClick"
                @handleSelected="handleSelected"
              />
            </b-col>

            <b-col xl="9" cols="12" v-if="ans == '4223'">
              <FifthDQuestion
                @handleClick="handleNextClick"
                @handleSelected="handleSelected"
              />
            </b-col>

            <b-col xl="9" cols="12" v-if="ans == '4226'">
              <FifthEQuestion
                @handleClick="handleNextClick"
                @handleSelected="handleSelected"
              />
            </b-col>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />
        <!-- <div
          class="d-flex w-100 justify-content-center align-items-center question-card__right-col-text mt-5"
        >
          <span>Walter gropiuserf 6, Amersfoort</span>
        </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";
import FifthAQuestion from "./FifthA_Question.vue";
import FifthBQuestion from "./FifthB_Question.vue";
import FifthCQuestion from "./FifthC_Question.vue";
import FifthDQuestion from "./FifthD_Question.vue";
import FifthEQuestion from "./FifthE_Question.vue";

export default {
  name: "Fifth_Question",
  components: {
    FifthAQuestion,
    FifthBQuestion,
    FifthCQuestion,
    FifthDQuestion,
    FifthEQuestion,
    GoogleMapSection,
  },
  props: {
    question: {
      type: String,
      required: true,
    },
    ans: {
      type: String,
    },
    max: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      value: "",
      houseRegex: /^[1-9]+$/,
    };
  },

  methods: {
    handleNextClick(val) {
      if (val === "dak isoleren") {
        console.log("val: inside fifth ", val);
        this.$emit("nextQuestion", "6", val);
      } else {
        this.$emit("handleClick", val);
        this.$emit("nextQuestion", "analyze", val);
      }
    },
    handleSelected(arr) {
      this.$emit("handleNextSelected", arr);
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "4");
    },
  },

  computed: {
    houseNumberValidation() {
      return this.houseRegex.test(this.value) || this.value === "";
    },
    isValidForm() {
      return this.houseNumberValidation;
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/secondQuestion.png);
    background-size: 100% 100% !important;
  }
}
.info {
  &__img {
    height: 20px;
    width: 20px;
  }
}
</style>
