import LeadService from "@/services/LeadService.js";
import axios from "axios";

const state = {
  getErrorResponse: {},
  leadLoading: false,
  leadResponse: {},
  zonnelResponse: {},
  addressDetails: {},
  lastAnswers: null,
  address: {},
  location: {
    lat: 0,
    lng: 0,
  },
  postdata: {
    zip: '',
    house_number: '',
  },
  errorResponses: {
    zip: false,
    house_number: false,
  },
  recentAddresses: [],
  street: null,
  city: null,
  houseNumber: '', 

};

const getters = {
  leadLoading: (state) => state.leadLoading,
  leadResponse: (state) => state.leadResponse,
  zonnelResponse: (state) => state.zonnelResponse,
  address: (state) => state.address,
  location: (state) => state.location,
  addressDetails: (state) => state.addressDetails,
  lastAnswers: (state) => state.lastAnswers,
  getErrorResponses: (state) => state.getErrorResponse,
  recentAddresses: (state) => state.recentAddresses,
  street: (state) => state.street,
  city: (state) => state.city,
  houseNumber: (state) => state.houseNumber,
};

const actions = {
  async getStreets({ commit }, payload) {
    commit("setLeadLoading", true);

    const apiKey = "P6JTU52clKYjZca8";

    try {
      const apiUrl = `https://api.pro6pp.nl/v2/autocomplete/nl?authKey=${apiKey}&postalCode=${payload.zip}&streetNumberAndPremise=${payload.house_number}`;
      const { data } = await axios.get(apiUrl);

      const street = data.street || data.address;
      const city = data.settlement || data.city;

      if (street && city) {
        commit("setStreet", street);
        commit("setCity", city);
      } else {
        console.warn("Geen straat- of stadsinformatie beschikbaar");
      }

      commit("setAddress", data);
      commit("setLocation", {
        lat: data.lat,
        lng: data.lng,
      });
      commit("addRecentAddress", data);

      payload.onSuccess();
    } catch (error) {
      payload.onError();

      commit("setAddress", {});
      commit("setLocation", {
        lat: 50.5039,
        lng: 4.4699,
      });
      console.error("Fout bij het ophalen van het adres:", error);
    } finally {
      commit("setLeadLoading", false);
    }
  },

  async saveAddress({ commit }, payload) {
    commit("setPostData", {
      zip: payload.zip,
      house_number: payload.house_number,
    });
    commit("setStreet", payload.street);
    commit("setCity", payload.city);
  },
};

const mutations = {
  setLeadLoading(state, payload) {
    state.leadLoading = payload;
  },
  setLeadResponse(state, payload) {
    state.leadResponse = payload;
  },
  setZonnelResponse(state, payload) {
    state.zonnelResponse = payload;
  },
  setLastAnswers(state, payload) {
    state.lastAnswers = payload;
  },
  setLocation(state, payload) {
    state.location = payload;
  },
  setAddress(state, payload) {
    state.addressDetails = payload;
  },
  addRecentAddress(state, address) {
    state.recentAddresses.push(address);
  },
  setPostData(state, payload) {
    state.postdata.zip = payload.zip;
    state.postdata.house_number = payload.house_number;
  },
  setStreet(state, street) {
    state.street = street; 
  },
  setCity(state, city) {
    state.city = city; 
  },
  SetHouseNumber(state, houseNumber) {
    state.houseNumber = houseNumber;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
