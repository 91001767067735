<template>
  <div class="solar">
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center flex-column"
        >
          <p class="solar__title-small mb-0">Kunststof kozijnen</p>
          <p class="solar__title">
            Bespaar op kunststof kozijnen: onderhoudsvriendelijk
          </p>
          <p class="solar__description mt-2 mt-md-3 text-justify">
            Het onderhoud bij kunststof kozijnen is erg minimaal. Het enige wat
            je moet doen is ze goed schoon houden! Wanneer je dit niet doet kan
            het vuil permanent in je kozijnen blijven zitten.
          </p>
          <p class="solar__description text-justify">
            Het schoonmaken van je kozijnen kan je meepakken wanneer je de ramen
            van je woning gaat zemen.
          </p>
          <p class="solar__description text-justify">
            Daarnaast biedt de overheid nu ook subsidie aan op het glaswerk van
            kunststof kozijnen.
          </p>
        </b-col>
        <b-col cols="12" md="1"></b-col>
        <b-col
          cols="12"
          md="5"
          class="mt-md-0 mt-2 d-flex flex-column align-items-center justify-content-start position-relative"
        >
          <!-- <p class="solar__text">
            30% alleen van toepassing als u gebruik maakt van meerdere
            isolerende/verduurzamings maatregelen die onder ISDE vallen
          </p> -->
          <img
            src="@/assets/imgs/solar.png"
            class="img-fluid"
            alt="Solar Image"
            width="100%"
            height="auto"
          />
          <!-- <div class="solar__panel-text">
            <p class="mb-0 solar__panel-text-title">1.210.679</p>
            <p class="mb-0 solar__panel-text-sub-title">
              mensen gingen je voor
            </p>
          </div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SolarPanel",
};
</script>
<style lang="scss" scoped>
.solar {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #ffffff;

  &__title-small {
    font-size: 14px;
    color: #ffcc01;
    font-weight: 600;
  }

  &__title {
    font-size: 35px;
    color: #1d1729;
    font-weight: 700;
    line-height: 40px;
  }

  &__description {
    font-size: 16px;

    letter-spacing: 2px;
    // line-height: 33px;
    line-height: 30px;
    color: #1d1729;
    font-weight: 600;
  }

  &__panel {
    &-text {
      position: absolute;
      text-align: center;
      right: 44px;
      top: 88px;

      &-title {
        // font-size: 36px;
        font-size: 25px;

        color: #1d1729;
        font-weight: 900;
      }

      &-sub-title {
        // font-size: 16px;
        font-size: 12px;
        color: #1d1729;
        font-weight: 500;
      }
    }

    // &-text > p {
    //   font-size: calc(1vw + 1vh);
    //   margin: 0;
    // }
  }
  &__text {
    font-size: 17px;
    color: #1d1729;
  }
  @media (max-width: 1199.99px) {
    &__title-small {
      font-size: 14px;
    }

    &__title {
      font-size: 35px;
      line-height: 40px;
    }

    &__description {
      font-size: 16px;
      line-height: 25px;
    }
  }
  @media (max-width: 992.99px) {
    &__title-small {
      font-size: 13px;
    }

    &__title {
      font-size: 30px;
    }

    &__description {
      font-size: 14px;
    }
  }
  @media (max-width: 767.99px) {
    padding-top: 30px;
    padding-bottom: 30px;
    &__title-small {
      font-size: 12px;
    }

    &__title {
      font-size: 25px;
      line-height: 30px;
    }

    &__description {
      font-size: 12px;
      line-height: 20px;
    }
  }
  @media (max-width: 577.99px) {
    &__title-small {
      font-size: 11px;
    }

    &__title {
      font-size: 20px;
      line-height: 20px;
    }

    &__description {
      font-size: 11px;
      line-height: 15px;
    }
  }
}
</style>
